<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="12"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            size="50px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ informacoes[0].cliente.nome }}
              </h4>
              <span class="card-text">{{ cpfCNPJ(informacoes[0].cliente.cpf_cnpj) }}</span>
              <br>

              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ informacoes[0].cliente.telefone ? phoneLabel(informacoes[0].cliente.telefone) : '' }}</span>
              <br>
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ informacoes[0].cliente.email }}</span>
              <br>
              <div v-if="informacoes[0].cliente.cidade">
                <feather-icon
                  icon="MapPinIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ informacoes[0].cliente.cidade.nome }}</span>
                <br>
              </div>

              <b-badge
                class="mt-1"
                :variant="resolveBadgeVarianteStatusOs(informacoes[0].status_os.nome)"
              >
                {{ informacoes[0].status_os.nome }}
              </b-badge>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                variant="primary"
                @click="gerarPDF"
              >
                Gerar OS
              </b-button>
            </div>
            <div class="d-flex justify-content-start">
              <b-button
                v-if="informacoes[0].status_os.nome === 'EM REPARO'"
                class="mt-1"
                variant="info"
                size="sm"
                @click="setStatus(informacoes[0].status_os.nome)"
              >
                Confirmar Reparo concluído
              </b-button>
              <b-button
                v-if="informacoes[0].status_os.nome === 'REPARO CONCLUÍDO'"
                class="mt-1"
                variant="success"
                size="sm"
                @click="setStatus(informacoes[0].status_os.nome)"
              >
                Confirmar Reparo Entregue
              </b-button>
            </div>
            <div>
              <b-button
                v-if="informacoes[0].status_os.nome === 'LAUDO'"
                v-show="user.funcao !== 'ATENDENTE'"
                v-b-tooltip.hover.top="'Negar'"
                class="mt-1 float-left cursor-pointer mr-1"
                variant="outline-warning"
                size="sm"
                @click="Negar(informacoes[0])"
              >
                Negar
                <feather-icon
                  class="color-icon"
                  size="16"
                  icon="ThumbsDownIcon"
                />
              </b-button>
              <!-- v-if="!informacoes[0].data_fechamento" -->
              <b-button
                v-if="!informacoes[0].data_fechamento"
                v-show="user.funcao !== 'ATENDENTE'"
                v-b-tooltip.hover.top="'Realizar troca'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mt-1 ml-1 float-right cursor-pointer"
                variant="outline-info"
                size="sm"
                @click="openModalRealizarTroca()"
              >
                <feather-icon
                  icon="RepeatIcon"
                  class="mr-50"
                />
              </b-button>
              <b-button
                v-if="informacoes[0].status_os_id !== idOSCancelado
                  && informacoes[0].status_os_id !== idOSTrocado && informacoes[0].data_fechamento"
                v-show="user.funcao !== 'ATENDENTE'"
                v-b-tooltip.hover.top="'REINCIDÊNCIA'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mt-1 cursor-pointer"
                variant="warning"
                size="sm"
                @click="openModalReincidencia()"
              >
                <feather-icon
                  icon="RepeatIcon"
                  class="mr-50"
                />
              </b-button>
              <b-button
                  v-if="!informacoes[0].status_os_id !== idOSCancelado"
                  v-show="user.funcao !== 'ATENDENTE'"
                  v-b-tooltip.hover.top="'Cancelar OS'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1 float-right cursor-pointer"
                  variant="outline-danger"
                  size="sm"
                  @click="openModalCancelarOs()"
              >
                <feather-icon
                    icon="XSquareIcon"
                    class="mr-50"
                />
              </b-button>
              <div
                v-if="informacoes[0].data_fechamento"
                class="d-flex justify-content-start"
              >
                <b-button
                  v-if="!informacoes[0].status_os_id !== idOSCancelado"
                  v-show="user.funcao !== 'ATENDENTE'"
                  v-b-tooltip.hover.top="'Cancelar OS'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1 float-right cursor-pointer"
                  variant="outline-danger"
                  size="sm"
                  @click="openModalCancelarOs()"
                >
                  <feather-icon
                    icon="XSquareIcon"
                    class="mr-50"
                  />
                </b-button>
                <b-button
                  v-if="informacoes[0].status_os_id !== idOSCancelado"
                  v-show="user.funcao !== 'ATENDENTE'"
                  v-b-tooltip.hover.top="'Realizar troca'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1 ml-1 float-right cursor-pointer"
                  variant="outline-info"
                  size="sm"
                  @click="openModalRealizarTroca()"
                >
                  <feather-icon
                    icon="RepeatIcon"
                    class="mr-50"
                  />
                </b-button>
              </div>
              <div v-else>
                <b-button
                  v-if="informacoes[0].status_os_id !== idOSCancelado
                    && informacoes[0].status_os_id !== idOSTrocado"
                  v-show="user.funcao !== 'ATENDENTE'"
                  v-b-tooltip.hover.top="'REINCIDÊNCIA'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1 cursor-pointer"
                  variant="warning"
                  size="sm"
                  @click="openModalReincidencia()"
                >
                  <feather-icon
                    icon="RepeatIcon"
                    class="mr-50"
                  />
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div
            v-b-tooltip.hover.top="'Quantidade de dias em aberto'"
            class="d-flex align-items-center mr-2"
          >
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="CalendarIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ diasEmAberto(informacoes[0].data_abertura, informacoes[0].data_fechamento) }}
              </h5>
              <small>QDA</small>
            </div>
          </div>

          <div
            v-b-tooltip.hover.top="'Reagendamento de Visita'"
            class="d-flex align-items-center mr-1"
          >
            <b-avatar
              variant="light-warning"
              rounded
            >
              <feather-icon
                icon="CalendarIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ informacoes[0].total_reagendamento_visita }}
              </h5>
              <small>RV</small>
            </div>
          </div>
          <div
            v-b-tooltip.hover.top="'Reagendamento de Reparo'"
            class="d-flex align-items-center"
          >
            <b-avatar
              variant="light-info"
              rounded
            >
              <feather-icon
                icon="CalendarIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ informacoes[0].total_reagendamento_reparo }}
              </h5>
              <small>RR</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        class="mt-1"
        xl="12"
      >
        <table class="mt-2 mt-xl-0 w-120 mt-1">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ServerIcon"
                class="mr-75"
              />
              <span class="font-weight-bold mr-1">Sinistro</span>
            </th>
            <td>
              #{{ informacoes[0].sinistro }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold mr-1">Atendente</span>
            </th>
            <td>
              {{ informacoes[0].atendente? fisrtLastName(informacoes[0].atendente.name) : '' }}
            </td>
            <feather-icon
              v-show="user.funcao !== 'ATENDENTE'"
              v-b-tooltip.hover.top="'Transferir Atendimento'"
              icon="RepeatIcon"
              class="ml-2 cursor-pointer"
              @click="openModalTransferirAtendente()"
            />
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ApertureIcon"
                class="mr-75"
              />
              <span class="font-weight-bold mr-1">Atendimento</span>
            </th>
            <td>
              {{ informacoes[0].tipo_atendimento }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Abertura</span>
            </th>
            <td>
              {{ informacoes[0].data_abertura ? formatTimezone(informacoes[0].data_abertura) : '' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Fechamento</span>
            </th>
            <td>
              {{ informacoes[0].data_fechamento ? formatTimezone(informacoes[0].data_fechamento) : '' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CodesandboxIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Produto</span>
            </th>
            <td class="pb-50">
              {{ informacoes[0].produto.nome }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Valor</span>
            </th>
            <td class="pb-50">
              {{ moedaBR(informacoes[0].produto.valor_custo) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CodesandboxIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Marca</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ informacoes[0].produto.marca.nome }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CommandIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Linha</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ informacoes[0].produto.linha.nome }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="AwardIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Seguradora</span>
            </th>
            <td class="pb-50">
              {{ informacoes[0].produto.seguradora ? informacoes[0].produto.seguradora.nome : '' }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-modal
      ref="modalCancelarOs"
      title="Cancelar Os"
      ok-title="Cancelar"
      cancel-title="Fechar"
      @ok="cancelarOs"
    >
      <div>
        <b-form-group>
          <b-form-textarea v-model="motivoCancelarOs" />
        </b-form-group>
      </div>
    </b-modal>
    <b-modal
      ref="modalTransferirAtendente"
      ok-title="Transferir"
      cancel-title="Cancelar"
      cancel-variant="danger"
      title="Transferir Atendimento"
      no-close-on-backdrop
      :ok-disabled="descricaoTransferenciaAtendente === ''"
      @ok="transferirAtendente"
    >
      <div>
        <label>Selecione o Atendente</label>
        <v-select
          v-model="selectedAtendente"
          :options="optionsAtendente"
        />
        <label>Descrição: </label>
        <b-form-textarea v-model="descricaoTransferenciaAtendente" />
      </div>
    </b-modal>
    <b-modal
      ref="modalRealizarTroca"
      title="Realizar Troca"
      ok-title="Realizar Troca"
      cancel-title="Cancelar"
      cancel-variant="danger"
      size="xl"
      @hidden="resetModal"
    >
      <div>
        <!-- realizarTroca() -->
        <b-row class="mb-2">
          <b-col>
            <label><strong>Informe a descrição da troca: </strong></label>
            <b-form-textarea
              v-model="descricaoTroca"
            />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <label><strong>Informe a taxa do laudo:</strong></label>
            <money
              v-model="taxaLaudo"
              v-bind="money"
              class="form-control"
            />
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col>
            <label><strong>Devolução do produto?</strong></label>
            <b-form-checkbox
              v-model="devolucao"
            />
          </b-col>

          <b-col>
            <label><strong>Estorno do orçamento?</strong></label>
            <b-form-checkbox
              v-model="estorno"
            />
          </b-col>
        </b-row>
        <div v-if="estorno">
          <b-row
            class="mb-1"
          >
            <b-col>
              <label><strong>Orçamentos a ser estornado</strong></label>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-table
                :fields="orcamentoEstornoFields"
                :items="orcamentos"
                responsive
                striped
              >
                <template #cell(select)="data">
                  <b-form-checkbox @change="(state) => selectOrcamentoEstorno({ state, id: data.item.id })" />
                </template>

                <template #cell(tecnico)="data">
                  {{ data.item.tipo_orcamento === 'AVULSO' ? data.item.nome_favorecido : data.item.prestador_servico.nome }}
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <label><strong>Informe o valor do estorno</strong></label>
              <money
                v-model="valorEstorno"
                v-bind="money"
                class="form-control"
              />
            </b-col>
          </b-row>
        </div>
      </div>

      <template #modal-footer="{ cancel, hide }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="success"
          :disabled="confirmTrocaModal || loadingTroca"
          @click="realizarTroca(hide)"
        >
          <b-spinner
            v-if="loadingTroca"
            small
            label="Loading..."
          />
          <div v-else>
            Realizar troca
          </div>
        </b-button>
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-view"
      ref="modal-view"
      hide-footer
      size="xl"
      title="PDF OS"
      @hidden="pdfPreview = ''"
    >
      <b-row id="frameRow">
        <b-col>
          <iframe
            id="myFrame"
            :src="pdfPreview"
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      ref="modalReincidencia"
      ok-title="Confirmar"
      cancel-title="Cancelar"
      cancel-variant="danger"
      title="REINCIDÊNCIA"
      :busy="load"
      @ok.prevent="reincidencia"
    >
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <div>
          <b-row align-h="center">
            <div class="d-flex flex-column flex-md-row justify-content-center">
              <b-col
                class="text-center mb-3 mb-md-0"
                md="auto"
              >
                <div class="flex-grow-1">
                  <h4 class="invoice-title">
                    Sinistro
                  </h4>
                  <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="HashIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="invoice-data-id"
                      v-model="reicidencia.sinistro"
                      type="number"
                    />
                  </b-input-group>
                </div>
              </b-col>
              <b-col
                class="text-center mb-3 mb-md-0"
                md="auto"
              >
                <div class="flex-grow-1">
                  <h4 class="invoice-title">
                    *Data Abertura:
                  </h4>
                  <flat-pickr
                    v-model="reicidencia.data_abertura"
                    class="form-control invoice-edit-input"
                  />
                </div>
              </b-col>
            </div>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <span class="font-weight-bold">*Atendentes</span>
              <v-select
                v-model="reicidencia.atendente"
                :options="optionsAtendente"
              />
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <span class="font-weight-bold">* Relato do Cliente: </span>
            <b-form-textarea v-model="reicidencia.relato_cliente" />
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge, VBTooltip, BModal, BFormGroup, BFormTextarea, BInputGroup, BInputGroupPrepend, BFormInput, BOverlay,
  BFormCheckbox,
  BTable,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { Money, VMoney } from 'v-money'
import router from '@/router'
import axios from '../../../../axios-auth'

export default {
  components: {
    BSpinner,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BModal,
    BFormGroup,
    BFormTextarea,
    vSelect,
    Money,
    flatPickr,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BOverlay,
    BFormCheckbox,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    money: VMoney,
  },
  props: {
    informacoes: {
      type: Array,
      required: true,
    },
    reload: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      orcamentoEstornoFields: [
        { key: 'select', label: 'Selecionar', class: 'text-center' },
        { key: 'tecnico', label: 'Técnico' },
        { key: 'tipo_orcamento', label: 'Tipo Orçamento' },
        { key: 'valor_total_aprovado', label: 'Valor' },
      ],
      orcamentosEstornoSelected: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      orcamentos: [],
      status: '',
      motivoCancelarOs: '',
      user: JSON.parse(localStorage.getItem('userData')),
      optionsAtendente: [],
      descricaoTransferenciaAtendente: '',
      selectedAtendente: [],
      eventoTransferenciaID: 2,
      descricaoTroca: '',
      taxaLaudo: '',
      pdfPreview: '',
      reicidencia: {
        sinistro: '',
        data_abertura: '',
        relato_cliente: '',
        atendente: '',
      },
      load: false,
      idOSCancelado: 8,
      idOSTrocado: 9,
      devolucao: false,
      estorno: false,
      valorEstorno: 0,
      loadingTroca: false,
    }
  },
  computed: {
    confirmTrocaModal() {
      if (this.estorno) {
        const checkValorEstornothis = this.valorEstorno <= 0
        const checkOrcamentosEstornoSelected = this.orcamentosEstornoSelected.length === 0
        if (checkValorEstornothis || checkOrcamentosEstornoSelected) {
          return true
        }
      }
      if (this.descricaoTroca === '' || this.taxaLaudo === '') {
        return true
      }
      return false
    },
  },
  watch: {
    informacoes(n) {
      this.informacoes = n
    },
  },
  created() {
    axios
      .get(`api/orcamento/listar/${this.$route.params.id}`)
      .then(res => {
        this.orcamentos = [...res.data.dados].filter(e => e.status === 'APROVADO - PAGO')
      })
  },
  methods: {
    selectOrcamentoEstorno(data) {
      const { state, id } = data

      if (state) {
        this.orcamentosEstornoSelected = [...this.orcamentosEstornoSelected, { id }]
      } else {
        this.orcamentosEstornoSelected = this.orcamentosEstornoSelected.filter(e => e.id !== id)
      }
    },
    resetModal() {
      this.devolucao = false
      this.estorno = false
      this.orcamentosEstornoSelected = []
      this.estorno = false
      this.valorEstorno = 0
      this.devolucao = false
    },
    setStatus(status) {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Você confirma essa ação?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const body = {
              ordem_de_servico_id: parseInt(this.$route.params.id),
              status_os: status,
            }
            axios
              .post('api/ordem_servico/finalizar', body)
              .then(() => {
                this.reload()
                this.$swal({
                  title: 'SUCESSO!',
                  icon: 'success',
                  text: 'CONCLUIDO COM SUCESSO!',
                  timer: 1500,
                  showConfirmButton: false,
                })
              })
              .catch(error => {
                if (error.response) {
                  this.$swal({
                    title: 'ERRO!',
                    icon: 'error',
                    text: `Ocorreu um erro: ${error.response.data.mensagem} `,
                    customClass: {
                      confimButton: 'btn btn-danger',
                    },
                  })
                }
              })
          }
        })
    },
    openModalCancelarOs() {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Você confirma essa ação?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$refs.modalCancelarOs.show()
        }
      })
    },
    cancelarOs() {
      const body = {
        ordem_de_servico_id: parseInt(this.$route.params.id),
        descricao: this.motivoCancelarOs.toUpperCase(),
      }
      axios
        .post('api/ordem_servico/cancelar', body)
        .then(() => {
          this.motivoCancelarOs = ''
          this.reload()
          this.$swal({
            title: 'SUCESSO!',
            icon: 'success',
            text: 'CANCELADO COM SUCESSO!',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          if (error.response) {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: `Ocorreu um erro: ${error.response.data.mensagem} `,
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          }
        })
    },
    async openModalTransferirAtendente() {
      const empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
      await this.getAtendentes(empresaID)
      this.$refs.modalTransferirAtendente.show()
    },
    getAtendentes(empresaId) {
      this.optionsAtendente = []
      axios.get(`api/empresas/atendentes/${empresaId}`)
        .then(response => {
          response.data.dados.map(item => {
            this.optionsAtendente.push({
              label: item.name,
              id: item.id,
            })
          })
        })
    },
    transferirAtendente() {
      const body = {
        atendente_id: this.selectedAtendente.id,
        motivo_evento_id: this.eventoTransferenciaID,
        descricao: this.descricaoTransferenciaAtendente,
      }

      axios.put(`api/ordem_servico/atualizar/atendente/${parseInt(this.$route.params.id)}`, body)
        .then(() => {
          this.reload()
          this.selectedAtendente = []
          this.descricaoTransferenciaAtendente = ''
          this.$swal({
            title: 'Atendente transferido!',
            icon: 'success',
            text: 'Atendente transferido!',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.selectedAtendente = []
          this.descricaoTransferenciaAtendente = ''
          if (error.response) {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: `Ocorreu um erro: ${error.response.data.mensagem} `,
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          }
        })
    },
    diasEmAberto(dataAbertura, dataFechamento) {
      if (!dataFechamento) {
        dataFechamento = new Date()
      }
      dataAbertura = new Date(dataAbertura)
      dataFechamento = new Date(dataFechamento)

      const diferenca = dataFechamento.getTime() - dataAbertura.getTime()
      const dias = Math.floor(diferenca / (1000 * 60 * 60 * 24))
      return dias
    },
    openModalRealizarTroca() {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Deseja confirmar a troca ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$refs.modalRealizarTroca.show()
          }
        })
    },
    moedaBR(valor) {
      const numero = parseFloat(valor)
      const moeda = numero.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
      return moeda
    },
    // eslint-disable-next-line no-unused-vars
    async realizarTroca(hide) {
      this.loadingTroca = true
      // eslint-disable-next-line no-unused-vars
      const body = {
        ordem_de_servico_id: parseInt(this.$route.params.id),
        taxa_laudo: this.taxaLaudo,
        motivo: this.descricaoTroca.toUpperCase(),
      }
      axios.post('api/ordem_servico/troca', body)
        .then(async () => {
          if (this.estorno) {
            const payload = {
              orcamentos_ids: this.orcamentosEstornoSelected,
              valor_orcamento: this.valorEstorno,
            }
            // Enviar os dados no payload do orçamento, valor
            await axios.post('api/orcamento/estorno', payload)
          }
          this.loadingTroca = false
          hide()
          this.reload()
          this.taxaLaudo = ''
          this.descricaoTroca = ''
          this.$swal({
            title: 'SUCESSO!',
            icon: 'success',
            text: 'TROCADO!',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.taxaLaudo = ''
          this.descricaoTroca = ''
          if (error.response) {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: `Ocorreu um erro: ${error.response.data.mensagem} `,
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          }
          this.loadingTroca = true
        })
    },
    formatValor(valor) {
      if (typeof valor === 'string') {
        valor = valor.replace(',', '.')
      }
      return Number(valor)
    },
    gerarPDF() {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Deseja gerar o PDF ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.pdfPreview = ''
            const body = {
              ordem_servico_id: parseInt(this.$route.params.id),
              empresa_id: JSON.parse(localStorage.getItem('userData'))?.empresa_id,
            }
            axios.post('api/ordem_servico/gerar/pdf', body, {
              headers: {
                Accept: 'application/pdf',
              },
              responseType: 'arraybuffer',
            })
              .then(res => {
                const file = new File([res.data], 'os.pdf', {
                  type: 'application/pdf',
                })
                const url = URL.createObjectURL(file)
                this.pdfPreview = url
                this.$refs['modal-view'].show()
              })
              .catch(error => {
                if (error.response) {
                  const texto = JSON.parse(Buffer.from(error.response.data))
                  this.$swal({
                    title: 'ERRO!',
                    icon: 'error',
                    text: `${texto.dados}`,
                    customClass: {
                      confimButton: 'btn btn-danger',
                    },
                  })
                }
              })
          }
        })
    },
    openModalReincidencia() {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Deseja confirmar a REINCIDÊNCIA ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            const empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
            await this.getAtendentes(empresaID)
            this.reicidencia.sinistro = this.informacoes[0].sinistro
            this.$refs.modalReincidencia.show()
          }
        })
    },
    clearFormReincidencia() {
      this.reicidencia.atendente = ''
      this.reicidencia.data_abertura = ''
      this.reicidencia.relato_cliente = ''
      this.reicidencia.sinistro = ''
    },
    reincidencia() {
      this.load = true
      this.reicidencia.atendente = this.reicidencia.atendente ? this.reicidencia.atendente.id : null
      axios.post(`api/ordem_servico/reincidencia/${this.$route.params.id}`, this.reicidencia)
        .then(res => {
          this.clearFormReincidencia()
          this.$swal({
            title: 'SUCESSO!',
            icon: 'success',
            text: 'REINCIDÊNCIA CADASTRADA COM SUCESSO!',
            timer: 1500,
            showConfirmButton: false,
          })
          router.push({ name: 'os-view', params: { id: res.data.dados.id } })
        })
        .catch(error => {
          this.load = false
          if (error.response) {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: `Ocorreu um erro: ${error.response.data.mensagem} `,
            })
          }
        })
    },

    Negar(item) {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Você confirma essa ação?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.post('api/ordem_servico/retorno/laudo', {
            ordem_de_servico_id: item.id,
          }).then(() => this.reload())
        }
      })
    },
  },
}
</script>

<style>
.iconeX {
  color: red;
}
.iconeTroca {
  color: lightsalmon;
}
</style>
