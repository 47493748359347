<template>
  <b-row>
    <!-- modal anexar arquivos-->
    <b-modal
      id="modal-comprovante"
      cancel-variant="outline-secondary"
      ok-title="Anexar"
      cancel-title="Cancelar"
      title="Anexar Comprovante"
      @ok="sendAnexo"
    >
      Selecione o arquivo:
      <b-form-file
        v-model="file"
        placeholder=""
        drop-placeholder="Arquivo"
      />
      <b-form>
        <b-form-group class="mt-1">
          <label for="observacao">Observação:</label>
          <b-form-input
            id="observacao"
            type="text"
            placeholder="Ex: Comprovante"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- modal visualizar arquivo-->
    <b-modal
      id="preview"
      ref="preview"
      hide-footer
      size="xl"
      title="Visualizar Comprovante"
    >
      <b-row>
        <b-col
          v-for="data in comprovantes"
          :key="data.id"
          md="2"
        >
          <div>
            <b-card
              class="hoverable"
              @click="visualizarArquivo(data.nome_arquivo)"
            >
              <b-avatar
                :src="endpoint + '/storage/' + data.nome_arquivo"
                size="100%"
                rounded
              />
              <b-card-title>
                {{ data.alias }}
              </b-card-title>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="viewArquivo"
      size="xl"
      title="Arquivo"
    >
      <iframe
        id="myFrame"
        :src="srcArquivo"
        style="width: 100%; height: 80vh"
      />
      <template #modal-footer="{}">
        <b-button
          class="mr-1"
          size="md"
          variant="outline-primary"
          @click="downloadArquivo"
        >
          Download
          <feather-icon
            class="plus-icon"
            size="16"
            icon="ArrowDownCircleIcon"
          />
        </b-button>
      </template>
    </b-modal>
    <b-modal
      ref="modalVisulizarDadosBancarios"
      title="Dados Bancários"
      ok-title="Ok"
      size="lg"
      cancel-title="Fechar"
      cancel-variant="danger"
      @hidden="showDadosBancarios = false"
    >
      <div v-if="showDadosBancarios">
        <b-col>
          <label><strong>NOME</strong></label>
          <b-form-input
            :value="viewDadosBancarios[0].nome ? viewDadosBancarios[0].nome : ''"
            disabled
          />
        </b-col>
        <b-col>
          <label for=""><strong>NOME FAVORECIDO</strong></label>
          <b-form-input
            :value="viewDadosBancarios[0].nome_favorecido ? viewDadosBancarios[0].nome_favorecido : ''"
            disabled
          />
        </b-col>
        <b-col>
          <label for=""><strong>CHAVE PIX</strong></label>
          <b-form-input
            :value="viewDadosBancarios[0].chave_pix ? viewDadosBancarios[0].chave_pix : ''"
            disabled
          />
        </b-col>
        <b-col>
          <label for=""><strong>BANCO</strong></label>
          <b-form-input
            :value=" viewDadosBancarios[0].banco ? viewDadosBancarios[0].banco : ''"
            disabled
          />
        </b-col>
        <b-col>
          <label for=""><strong>AGENCIA</strong></label>
          <b-form-input
            :value="viewDadosBancarios[0].agencia ? viewDadosBancarios[0].agencia : ''"
            disabled
          />
        </b-col>
        <b-col>
          <label for=""><strong>CONTA</strong></label>
          <b-form-input
            :value="viewDadosBancarios[0].conta ? viewDadosBancarios[0].conta : ''"
            disabled
          />
        </b-col>
      </div>
    </b-modal>
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(status)="data">
          <b-badge :variant="resolveBadgeVariantStatusOrcamento(data.item.status)">
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(data_pagamento)="data">
          {{ data.item.data_pagamento ? formatTimezone(data.item.data_pagamento) : '' }}
        </template>
        <template #cell(acoes)="data">
          <b-row>
            <b-col
              v-show="data.item.status === 'APROVADO - PAGO' || data.item.status === 'APROVADO - PAGO PARCIAL'"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Visualizar comprovante'"
                class="color-icon"
                size="16"
                icon="EyeIcon"
                @click="visualizarComprovante(data.item)"
              />
            </b-col>
            <b-col
              v-show="data.item.status === 'APROVADO - PAGO' || data.item.status === 'APROVADO - PAGO PARCIAL'"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Compartilhar comprovante'"
                class="plus-icon"
                size="16"
                icon="Share2Icon"
              />
            </b-col>
            <b-col
              v-show="data.item.status === 'APROVADO - AGUARDANDO PAGAMENT'"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Anexar comprovante'"
                class="color-icon"
                size="16"
                icon="FileTextIcon"
                @click="anexarComprovante(data.item)"
              />
            </b-col>
            <b-col
              v-show="data.item.status === 'APROVADO - AGUARDANDO PAGAMENTO'"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Dados Bancários'"
                class="color-icon ml-1 cursor-pointer"
                size="16"
                icon="DollarSignIcon"
                @click="visualizarDadosBancarios(data.item)"
              />
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BModal, BBadge, VBTooltip, BForm, BFormInput, BFormFile, BCard, BAvatar, BCardTitle, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '../../../../../axios-auth'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BModal,
    BBadge,
    BForm,
    BFormInput,
    BFormFile,
    BCard,
    BAvatar,
    BCardTitle,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      endpoint: process.env.VUE_APP_ROOT_API,
      nome_arquivo: null,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'status', label: 'status', sortable: true },
        { key: 'tipo_atendimento', label: 'Tipo de Atendimento', sortable: true },
        { key: 'valor_total_aprovado', label: 'Valor Aprovado', sortable: true },
        { key: 'data_pagamento', label: 'Data Pagamento', sortable: true },
        { key: 'acoes', label: 'Ações', class: 'd-flex' },
      ],
      items: [],
      status: [{
        1: 'Aguardando Pagamento', 2: 'Pago', 3: 'Atrasado', 4: 'Aguardando Auditoria', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      selected: { title: 'Selecione o tipo cobrança' },
      selected2: { title: 'Selecione o Técnico' },
      selected3: { title: 'Selecione o Perído' },
      selected1: {
        title: 'Aperture',
        icon: 'ApertureIcon',
      },
      option: [{ title: 'Visita' }, { title: 'Deslocamento' }, { title: 'Mão de Obra' }, { title: 'Peça' }],
      option2: [{ title: 'Tecnico 1' }, { title: 'Tecnico 2' }, { title: 'Tecnico 3' }, { title: 'Tecnico 4' }],
      option3: [{ title: 'Manhã' }, { title: 'Tarde' }, { title: 'Noite' }],
      date: null,
      dateDefault: null,
      timePicker: null,
      dateNtim: null,
      multiDate: null,
      rangeDate: null,
      humanDate: null,
      disableDate: null,
      inlineDate: null,
      orcamento_id: null,
      file: null,
      showDadosBancarios: false,
      viewDadosBancarios: [],
      comprovantes: [],
      srcArquivo: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.getOrcamentos()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    getOrcamentos() {
      this.items = []
      axios
        .get(`api/orcamento/listar/${this.$route.params.id}`)
        .then(res => {
          res.data.dados.map(item => {
            if (item.status === 'APROVADO - PAGO' || item.status === 'APROVADO - AGUARDANDO PAGAMENTO' || item.status === 'APROVADO - PAGO PARCIAL') {
              this.items.push(item)
            }
          })
        })
    },
    anexarComprovante(item) {
      this.orcamento_id = item.id
      this.$bvModal.show('modal-comprovante')
    },
    async sendAnexo() {
      const formData = new FormData()
      formData.append('arquivo', this.file)
      formData.append('orcamento_id', this.orcamento_id)

      await axios.post('/api/comprovante/store', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        this.getArquivos()
        this.$swal({
          title: 'Sucesso!',
          text: 'Arquivo enviado',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    async visualizarComprovante(item) {
      await this.getComprovantes(item.id)
      this.$bvModal.show('preview')
    },
    getComprovantes(id) {
      this.comprovantes = []
      axios
        .get(`api/comprovante/listar/${id}`)
        .then(res => {
          this.comprovantes = res.data.dados
        })
    },
    visualizarArquivo(nome) {
      this.srcArquivo = `${this.endpoint}/storage/${nome}`
      this.$bvModal.show('viewArquivo')
    },
    downloadArquivo() {
      fetch(this.srcArquivo)
        .then(response => response.blob())
        .then(blob => {
          const blobUrl = URL.createObjectURL(blob)
          const getExtensao = this.srcArquivo.split('.').pop()
          const linkDownload = document.createElement('a')
          linkDownload.href = blobUrl
          linkDownload.download = `arquivo.${getExtensao}`
          document.body.appendChild(linkDownload)
          linkDownload.click()
          document.body.removeChild(linkDownload)
          URL.revokeObjectURL(blobUrl)
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    resolveBadgeVariantStatusOrcamento(status) {
      if (status === 'APROVADO - PAGO') {
        return 'light-success'
      }
      return 'light-primary'
    },
    visualizarDadosBancarios(item) {
      this.viewDadosBancarios = []
      if (item.prestador_servico !== null) {
        this.viewDadosBancarios.push({
          agencia: item.prestador_servico.agencia,
          banco: item.prestador_servico.banco,
          chave_pix: item.prestador_servico.chave_pix,
          conta: item.prestador_servico.conta,
          nome: item.prestador_servico.nome,
        })
      } else {
        this.viewDadosBancarios.push({
          agencia: item.agencia,
          banco: item.banco,
          chave_pix: item.chave_pix,
          conta: item.conta,
          nome: item.nome,
          nome_favorecido: item.nome_favorecido,
        })
      }
      this.showDadosBancarios = true
      this.$refs.modalVisulizarDadosBancarios.show()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.nova-entidade {
  float: right;
  margin-right: 5px;
}

.nova-entidade:hover {
  color: blueviolet;
  cursor: pointer;
}
</style>
