<template>
  <div>
    <template v-if="loadData">
      <!-- First Row -->
      <b-row>
        <b-col
          md="3"
          xl="3"
          lg="3"
        >
          <o-s-informacoes-gerais-card
            :informacoes="userData"
            :reload="reload"
          />
          <o-s-time-line-card :time-line="timeLine" />
        </b-col>
        <b-col
          xl="9"
          lg="9"
          md="9"
        >
          <tabs
            :informacoes="userData"
            :reload="reload"
          />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import OSTimeLineCard from './OSTimeLineCard.vue'
import OSInformacoesGeraisCard from './OSInformacoesGeraisCard.vue'
import Tabs from './OSTabsCard.vue'

import axios from '../../../../axios-auth'

export default {
  components: {
    BRow,
    BCol,

    // Local Component
    OSTimeLineCard,
    OSInformacoesGeraisCard,
    Tabs,
  },
  data() {
    return {
      userData: [],
      loadData: false,
      timeLine: {
        dataAbertura: null,
        agendamento: false,
        dataAgendamento: null,
        aguardandoAprovacao: false,
        dataAguardandoAprovacao: null,
        emReparo: false,
        dataEmReparo: null,
        reparoConcluido: false,
        dataReparoConcluido: null,
        reparoEntregue: false,
        dataReparoEntregue: false,
        troca: false,
        dataTroca: null,
        negado: false,
        dataNegado: null,
        cancelado: false,
        dataCancelado: null,
        ok: null,
      },
    }
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.loadData = false
        this.getViewOs()
      },
      immediate: true, // Para chamar o handler imediatamente após a criação do componente
    },
  },
  async created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
    await this.getTimeLine()
  },
  methods: {
    reload() {
      window.location.reload()
    },
    getViewOs() {
      this.userData = []
      axios
        .get(`api/ordem_servico/view/${this.$route.params.id}`)
        .then(res => {
          this.timeLine.dataAbertura = this.formatTimezone(res.data.dados[0].data_abertura)
          res.data.dados.map(item => {
            this.userData.push(item)
          })
          this.loadData = true
        })
        .catch(error => {
          if (error.response) {
            this.$swal({
              title: 'Error!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
          this.$router.replace('/')
        })
    },
    getTimeLine() {
      axios
        .get(`api/ordem_servico/time_line/${this.$route.params.id}`)
        .then(res => {
          res.data.dados.map(item => {
            const statusId = item.status_os_id
            switch (statusId) {
              case 2:
                this.timeLine.agendamento = true
                this.timeLine.dataAgendamento = this.formatTimezone(item.created_at)
                break
              case 3:
                this.timeLine.aguardandoAprovacao = true
                this.timeLine.dataAguardandoAprovacao = this.formatTimezone(item.created_at)
                break
              case 4:
                this.timeLine.emReparo = true
                this.timeLine.dataEmReparo = this.formatTimezone(item.created_at)
                break
              case 5:
                this.timeLine.reparoConcluido = true
                this.timeLine.dataReparoConcluido = this.formatTimezone(item.created_at)
                break
              case 6:
                this.timeLine.reparoEntregue = true
                this.timeLine.dataReparoEntregue = this.formatTimezone(item.created_at)
                break
              case 7:
                this.timeLine.negado = true
                this.timeLine.dataNegado = this.formatTimezone(item.created_at)
                break
              case 8:
                this.timeLine.cancelado = true
                this.timeLine.dataCancelado = this.formatTimezone(item.created_at)
                break
              case 9:
                this.timeLine.troca = true
                this.timeLine.dataTroca = this.formatTimezone(item.created_at)
                break
              default:
                this.timeLine.ok = null
            }
          })
        })
    },
    formatTimezone(dados) {
      const a = dados.split(' ')
      const b = a[0].split('-')
      const dataBr = `${b[2]}/${b[1]}/${b[0]}`
      return dataBr
    },
    dataHora(dados, op = false) {
      const a = dados
      const b = a.split('T')
      const c = b[0].split('-')
      const d = b[1].split('.')
      const e = d[0].split(':')
      const dataBr = `${c[2]}/${c[1]}/${c[0]}`
      const horaBr = `${e[0]}:${e[1]}`
      const dataHora = `${dataBr} ${horaBr}`
      if (op) {
        return dataBr
      }
      return dataHora
    },
  },
}
</script>

<style>

</style>
