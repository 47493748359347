<template>
  <b-overlay
    :show="load"
    variant="transparent"
  >
    <b-row>
      <b-modal
        id="visualizar-arquivo"
        size="xl"
        title="Arquivo"
        @hidden="editavel=false"
      >
        <iframe
          id="myFrame"
          :src="srcArquivo"
        />
        <template #modal-footer="{}">
          <b-button
            class="mr-1"
            size="md"
            variant="outline-primary"
            @click="downloadArquivo"
          >
            Download
            <feather-icon
              class="plus-icon"
              size="16"
              icon="ArrowDownCircleIcon"
            />
          </b-button>
        </template>
      </b-modal>
      <!-- modal anexar arquivos-->
      <b-modal
        id="modal-anexo"
        cancel-variant="outline-secondary"
        ok-title="Anexar"
        cancel-title="Cancelar"
        title="Anexar Arquivo"
        @ok="sendAnexo"
      >
        Selecione o arquivo:
        <b-form-file
          v-model="file"
          placeholder=""
          drop-placeholder="Arquivo"
        />
        <b-form>
          <b-form-group class="mt-1">
            <label for="observacao">Observação:</label>
            <b-form-input
              id="observacao"
              type="text"
              placeholder="Ex: Comprovante"
            />
          </b-form-group>
        </b-form>
      </b-modal>
      <b-col
        md="12"
        class="my-1"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-anexo
          class="float-right"
          variant="outline-primary"
          size="sm"
        >
          <feather-icon
            icon="PlusCircleIcon"
            class="mr-50"
          />
          <span class="align-middle">Novo Arquivo</span>
        </b-button>
        <b-button
          class="float-right mr-3"
          variant="outline-primary"
          size="sm"
          @click="gerarLaudo"
        >
          <feather-icon
            icon="FileTextIcon"
            class="mr-50"
          />
          <span class="align-middle">Gerar Laudo</span>
        </b-button>
      </b-col>
      <b-row>
        <b-col
          v-for="item in items"
          :key="item.id"
          md="3"
          lg="3"
        >
          <b-card
            class="hoverable"
            :img-src="endpoint + '/storage/' + item.nome_arquivo"
            img-alt="Anexo"
            img-width="150"
            img-height="150"
            img-top
            @click="visualizarArquivo(item.nome_arquivo)"
          >
            <b-card-text>
              {{ item.alias }}
            </b-card-text>
            <div class="d-flex gap-2">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click.stop="abrirModal(item.id)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="color-icon align-items-center"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click.stop="deletArquivo(item.id)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="color-icon align-items-center"
                />
              </b-button>
            </div>
          </b-card>
        </b-col>
        <b-modal
          v-model="showModal"
          title="Atualizar Alias"
          hide-footer
        >
          <b-form @submit.prevent="atualizarAlias">
            <b-form-group
              label="Novo alias"
              label-for="alias"
            >
              <b-form-input
                id="alias"
                v-model="alias"
              />
            </b-form-group>
            <b-button
              type="submit"
              variant="primary"
              style="margin-top: 5px;"
            >
              Salvar
            </b-button>
          </b-form>
        </b-modal>
        <b-modal
          id="modal-selecao-imagens"
          title="Selecionar Imagens para o Laudo"
          size="xl"
          ok-title="Gerar Laudo"
          cancel-title="Cancelar"
          cancel-variant="danger"
          @ok="gerarLaudoComImagens"
        >
          <b-row>
            <!-- Renderizar a lista de imagens disponíveis -->
            <b-col
              v-for="data in items"
              :key="data.id"
              md="2"
            >
              <div>
                <b-card
                  class="hoverable"
                  :class="{ selected: imagensSelecionadas.includes(data) }"
                  @click="toggleSelecaoImagem(data)"
                >
                  <b-avatar
                    :src="endpoint + '/storage/' + data.nome_arquivo"
                    size="100%"
                    rounded
                  />
                  <b-card-title>
                    {{ data.alias }}
                  </b-card-title>
                </b-card>
              </div>
            </b-col>
          </b-row>
          <label>Técnico/Prestador</label>
          <v-select
            v-model="prestadorSelected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionsPrestador"
            class="mb-1"
          />
          <b-form-group>
            <label><strong>Avaliação: </strong></label>
            <b-form-textarea v-model="avaliacaoDoLaudo" />
          </b-form-group>
        </b-modal>
        <b-modal
          id="modal-view"
          ref="modal-view"
          hide-footer
          size="xl"
          title="Laudo Técnico"
          @hidden="resetModalViewLaudo"
        >
          <b-row id="frameRow">
            <b-col>
              <iframe
                id="myFrame"
                :src="urlPreview"
              />
            </b-col>
          </b-row>
        </b-modal>
      </b-row>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormFile,
  BAvatar,
  BCard,
  BCardTitle,
  BFormGroup,
  BFormTextarea,
  BCardText,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from '../../../../../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormFile,
    BAvatar,
    BCard,
    BCardTitle,
    BFormGroup,
    BFormTextarea,
    BCardText,
    vSelect,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      load: true,
      items: [],
      showModal: false,
      itemToEdit: null,
      novoAlias: '',
      endpoint: process.env.VUE_APP_ROOT_API,
      file: null,
      srcArquivo: null,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      imagensSelecionadas: [],
      avaliacaoDoLaudo: '',
      urlPreview: '',
      empresaID: [],
      optionsPrestador: [],
      prestadorSelected: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.getArquivos()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
  },
  methods: {
    deletArquivo(itemId) {
      this.load = true
      this.$swal({
        title: 'Tem certeza?',
        text: 'Deseja deletar o arquivo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero deletar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(`api/arquivo/delete/${itemId}`)
            .then(res => {
              this.load = false
              if (res.data) {
                this.getArquivos()
                this.$swal({
                  icon: 'success',
                  title: 'Deletado!',
                  text: 'Arquivo deletado com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => {
              // Error
              this.load = false
              if (error.response) {
                this.$swal({
                  title: 'Erro ao deletar Arquivo!',
                  text: error.response.data.mensagem,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }
            })
        }
      })
    },
    abrirModal(itemId) {
      this.itemToEdit = this.items.find(item => item.id === itemId)
      this.novoAlias = this.itemToEdit.alias || ''
      this.showModal = true
    },
    atualizarAlias() {
      this.load = true
      this.novoAlias = this.alias
      axios.put(`api/arquivo/atualizar/alias/${this.itemToEdit.id}`, { alias: this.novoAlias })
        .then(() => {
          this.load = false
          this.itemToEdit.alias = this.novoAlias // atualiza o alias localmente
          this.showModal = false // fecha o modal
          this.$swal({
            title: 'Alias atualizado!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.load = false
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: `Ocorreu um erro: ${error.response.data.mensagem}`,
          })
        })
    },
    visualizarArquivo(nome) {
      this.srcArquivo = `${this.endpoint}/storage/${nome}`
      this.$bvModal.show('visualizar-arquivo')
    },
    downloadArquivo() {
      fetch(this.srcArquivo)
        .then(response => response.blob())
        .then(blob => {
          const blobUrl = URL.createObjectURL(blob)
          const getExtensao = this.srcArquivo.split('.').pop()
          const linkDownload = document.createElement('a')
          linkDownload.href = blobUrl
          linkDownload.download = `arquivo.${getExtensao}`
          document.body.appendChild(linkDownload)
          linkDownload.click()
          document.body.removeChild(linkDownload)
          URL.revokeObjectURL(blobUrl)
        })
    },
    getArquivos() {
      this.items = []
      axios
        .get(`api/arquivo/listar/${this.$route.params.id}`)
        .then(res => {
          res.data.dados.map(item => {
            this.items.push(item)
          })
          this.load = false
          this.totalRows = this.items.length
        })
    },
    async sendAnexo() {
      const formData = new FormData()
      formData.append('arquivo', this.file)
      formData.append('ordem_de_servico_id', this.$route.params.id)

      await axios.post('/api/arquivo/store', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        this.getArquivos()
        this.$swal({
          title: 'Sucesso!',
          text: 'Arquivo enviado',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    onRowClicked() {
      this.$bvModal.show('preview')
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async gerarLaudo() {
    // Limpar a lista de imagens selecionadas, se houver
      this.imagensSelecionadas = []
      await this.getPrestadores()
      // Abrir o modal de seleção de imagens
      this.$bvModal.show('modal-selecao-imagens')
    },
    toggleSelecaoImagem(item) {
    // Verificar se a imagem já foi selecionada
      const index = this.imagensSelecionadas.findIndex(img => img.id === item.id)
      if (index > -1) {
      // Se sim, remover da lista de imagens selecionadas
        this.imagensSelecionadas.splice(index, 1)
      } else {
      // Se não, adicionar à lista de imagens selecionadas
        this.imagensSelecionadas.push(item)
      }
    },
    resetSelecaoImagens() {
      this.imagensSelecionadas = []
    },
    resetModalViewLaudo() {
      this.imagensSelecionadas = []
      this.prestadorSelected = []
      this.avaliacaoDoLaudo = ''
    },
    getPrestadores() {
      this.optionsPrestador = []
      axios
        .get(`api/prestador_servico/${this.empresaID}`)
        .then(res => {
          res.data.dados.map(item => {
            this.optionsPrestador.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    async gerarLaudoComImagens() {
      const body = {
        ordem_servico_id: parseInt(this.$route.params.id),
        imagems: this.imagensSelecionadas,
        avaliacao: this.avaliacaoDoLaudo,
        prestador: this.prestadorSelected.id,
        empresa_id: JSON.parse(localStorage.getItem('userData'))?.empresa_id,
      }
      axios
        .post('api/ordem_servico/gerar/laudo', body, {
          headers: {
            Accept: 'application/pdf',
          },
          responseType: 'arraybuffer',
        })
        .then(res => {
          const file = new File([res.data], 'laudo.pdf', {
            type: 'application/pdf',
          })
          const url = URL.createObjectURL(file)
          this.urlPreview = url
          this.$refs['modal-view'].show()
        })
    },
  },
}
</script>
<style>
#myFrame {
  width:100%;
  height:700px;
  border: none;
}
.selected {
    border: 2px solid blue;
}
</style>
