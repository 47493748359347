<template>
  <b-row>
    <!-- modal anexar arquivos-->
    <b-modal
      id="modal-orcamento"
      ref="modalOrcamento"
      cancel-variant="outline-secondary"
      size="xl"
      ok-title="Enviar"
      :ok-disabled="!prestadorAvaible"
      cancel-title="Cancelar"
      title="Enviar Orçamento"
      @hidden="clearFormNovoOrcamento()"
      @ok="storeNewOrcamento"
    >
      <b-col
        md="12"
      >
        <b-form-group>
          <label class="d-inline">Tipo de Solicitação</label>
          <v-select
            v-model="selected3"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="option3"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="selected3.title === 'Normal'"
        md="12"
      >
        <b-form-group>
          <label class="d-inline">Técnico/Prestador</label>
          <v-select
            v-model="prestadorSelected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionsPrestador"
          />
        </b-form-group>
      </b-col>
      <b-col md="12" />
      <b-col
        v-if="selected3.title === 'Avulso'"
        md="12"
      >
        <label class="d-inline">Valor</label>
        <b-input-group>
          <money
            v-model="valorAvulso"
            v-bind="money"
            class="form-control"
          />
        </b-input-group>
      </b-col>
      <b-col
        v-if="selected3.title === 'Avulso'"
        md="12"
      >
        <label class="d-inline">Nome Favorecido</label>
        <b-input-group>
          <b-form-input
            v-model="nomeFavorecido"
            placeholder="Joao"
          />
        </b-input-group>
      </b-col>
      <b-col
        v-if="selected3.title === 'Avulso'"
        md="12"
      >
        <label class="d-inline">Nome</label>
        <b-input-group>
          <b-form-input
            v-model="nomeServico"
            placeholder="Joao"
          />
        </b-input-group>
      </b-col>
      <b-col
        v-if="selected3.title === 'Avulso'"
        md="12"
      >
        <label class="d-inline">Agencia</label>
        <b-input-group>
          <b-form-input v-model="agencia" />
        </b-input-group>
      </b-col>
      <b-col
        v-if="selected3.title === 'Avulso'"
        md="12"
      >
        <label class="d-inline">Conta</label>
        <b-input-group>
          <b-form-input v-model="conta" />
        </b-input-group>
      </b-col>
      <b-col
        v-if="selected3.title === 'Avulso'"
        md="12"
      >
        <label class="d-inline">Chave Pix</label>
        <b-input-group>
          <b-form-input v-model="chavePix" />
        </b-input-group>
      </b-col>
      <b-col
        v-if="selected3.title === 'Avulso'"
        md="12"
        class="mt-1"
      >
        <!-- textarea -->
        <b-input-group prepend="Descrição do Serviço">
          <b-form-textarea v-model="descricaoServicoAvulso" />
        </b-input-group>
      </b-col>
      <b-col v-if="selected3.title === 'Normal'">
        <div>
          <b-form
            ref="form"
            :style="{height: trHeight}"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >

            <!-- Row Loop -->
            <b-row
              v-for="(item, index) in items2"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <!-- Tipo de serviço-->
              <b-col
                md="12"
                sm="12"
              >
                <b-form-group>
                  <label class="d-inline">Tipo de Serviço</label>
                  <v-select
                    v-model="servicoSelected[index]"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsTipoServico"
                  />
                </b-form-group>
              </b-col>
              <!-- Item Name -->
              <b-col
                md="12"
                sm="12"
              >
                <b-form-group
                  label="Descrição"
                  label-for="item-name"
                >
                  <b-form-input
                    id="item-name"
                    v-model="servicoDescricao[index]"
                    type="text"
                    placeholder="Descrição"
                  />
                </b-form-group>
              </b-col>

              <!-- Cost -->
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group
                  label="Valor"
                  label-for="cost"
                >
                  <!-- <b-form-input
                    id="cost"
                    v-model="valorSolicitado[index]"
                    type="number"
                    placeholder="32"
                  /> -->
                  <money
                    v-model="valorSolicitado[index]"
                    v-bind="money"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>

              <!-- Quantity -->
              <b-col
                md="4"
                sm="12"
              >
                <b-form-group
                  label="Quantidade"
                  label-for="quantity"
                >
                  <b-form-input
                    id="quantity"
                    v-model="servicoQuantidade[index]"
                    type="number"
                    default-value="1"
                    placeholder="1"
                  />
                </b-form-group>
              </b-col>

              <!-- Profession -->

              <!-- Remove Button -->
              <b-col
                md="2"
                sm="12"
              >
                <b-button
                  v-show="index >= 1"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-25"
                  />
                </b-button>
              </b-col>

              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>

          </b-form>
        </div>
        <b-button
          v-show="servicoSelected[0] !== 'VISITA'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          style="float: right;"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add Novo Serviço</span>
        </b-button>
        <b-row>
          <span
            style="float: right"
            class="d-inline"
          >
            Valor Total: <b>R$ {{ previewValorOcamento }}</b>
          </span>
        </b-row>

        <b-row v-if="!prestadorAvaible && Object.keys(prestadorSelected).length !== 0">
          <b-alert
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              Técnico/Prestador não possui agência/conta ou chave pix cadastrada no sistema
            </h4>
          </b-alert>
        </b-row>

      </b-col>

      <b-col v-if="selected3.title === 'Internet'">
        <validation-observer ref="orcamento-internet-validation">
          <b-form>
            <b-row>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Valor"
                    rules="required"
                  >
                    <label>Valor</label>
                    <b-form-input
                      v-model="valorAvulso"
                      v-money="money"
                      :state="errors.length > 0 ? false : null"
                    />
                    {{ errors }}
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="9">
                <b-form-group>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Link"
                    rules="required"
                  >
                    <label>Link</label>
                    <b-form-input
                      v-model="linkOrcamentoInternet"
                      :state="errors.length > 0 ? false : null"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Linha Digitável"
                    rules="required"
                  >
                    <label>Linha Digitável</label>
                    <b-form-input
                      v-model="linhaDigitavelOrcamentoInternet"
                      :state="errors.length > 0 ? false : null"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Descricao"
                    rules="required"
                  >
                    <label>Descrição</label>
                    <b-form-input
                      v-model="descricaoServicoAvulso"
                      :state="errors.length > 0 ? false : null"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <template
        v-if="selected3.title === 'Internet'"
        #modal-footer="{ cancel, hide }"
      >
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            @click="storeNewOrcamento(hide)"
          >
            Enviar
          </b-button>

          <b-button
            variant="outline-secondary"
            class="float-right"
            @click="cancel()"
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-col
      md="12"
      class="my-1"
    >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="float-right"
        variant="outline-primary"
        size="sm"
        @click="openModalOrcamento"
      >
        <feather-icon
          icon="PlusCircleIcon"
          class="mr-50"
        />
        <span class="align-middle">Novo Orçamento</span>
      </b-button>
      <b-button
        v-if="haveEstorno"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="float-right mr-2"
        variant="warning"
        size="sm"
        @click="() => selectOrcamento = !selectOrcamento"
      >
        <span class="align-middle">Confirmar estorno</span>
      </b-button>

      <div
        v-if="selectOrcamento"
        class="float-left"
      >
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="mr-2"
              variant="outline-success"
              size="sm"
              @click="$refs.confirmEsorno.show()"
            >
              <span class="align-middle">Confirmar</span>
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="mr-2"
              variant="danger"
              size="sm"
              @click="cancelSelectOrcamentoEstorno"
            >
              <span class="align-middle">Cancelar</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-col>

    <b-col cols="12">
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <b-table
          :items="selectOrcamento ?estornoFiltered : items"
          :fields="fields"
          striped
          responsive
        >
          <template #cell(selecionar)="data">
            <div v-if="selectOrcamento">
              <b-form-checkbox
                :checked="!!orcamentosSelectedEstorno.find(e => e.orcamentoId === data.item.id)"
                @change="(state) => selectOrcamentoEstorno({ state, item: data.item })"
              />
            </div>
          </template>
          <template #cell(created_at)="data">
            {{ dataHora(data.item.created_at) }}
          </template>
          <template #cell(valor_total_orcamento)="data">
            R$: {{ valorBr(data.item.valor_total_orcamento) }}
          </template>
          <template #cell(status)="data">
            <b-badge :variant="resolveBadgeVariantStatusOrcamento(data.item.status)">
              {{ data.item.status }}
            </b-badge>
          </template>
          <template #cell(acoes)="data">
            <b-row>
              <b-col>
                <feather-icon
                  v-b-tooltip.hover.top="'Visualizar Orçamento'"
                  class="color-icon cursor-pointer"
                  size="16"
                  icon="EyeIcon"
                  @click="openModalViewOrcamento(data.item.id, data.item.status)"
                />
              </b-col>
              <b-col>
                <feather-icon
                  v-if="data.item.status === 'AGUARDANDO AUDITORIA' || data.item.status === 'REVISÃO' || data.item.status === 'REVISÃO BANCÁRIA'"
                  v-b-tooltip.hover.top="'EDITAR'"
                  class="color-icon cursor-pointer"
                  size="16"
                  icon="ToolIcon"
                  @click="openModalEditOrcamento(data.item.id, data.item.status)"
                />
              </b-col>
              <b-col>
                <feather-icon
                  v-b-tooltip.hover.top="'Gerar link Assinaturas'"
                  class="color-icon"
                  size="16"
                  icon="Share2Icon"
                  @click="copiarLink(data.item.id)"
                />
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-overlay>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
    <b-modal
      ref="modalInforOcamento"
      title="Editar Orçamento"
      cancel-title="Cancelar"
      cancel-variant="danger"
      size="xl"
      @hidden="editavel = false && clearFormNovoOrcamento()"
      @ok="storeUpdateOrcamento"
    >
      <div>
        <hr>
        <b-row
          v-for="(dados,index) in infoOrcamento.servicos"
          :key="index"
          class="mb-1"
        >
          <b-col md="3">
            <label for=""><strong>Tipo Servico</strong></label>
            <b-form-select
              v-model="dados.tipo_servico"
              :disabled="!editavel"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsTipoServico"
              @change="updateOrcamentoCampo('tipo_servico',dados.tipo_servico, dados)"
            />
          </b-col>
          <b-col md="3">
            <label for=""><strong>Descrição</strong></label>
            <b-form-input
              v-model.lazy="dados.descricao"
              :disabled="!editavel"
              @change="updateOrcamentoCampo('descricao',dados.descricao, dados)"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>Observação</strong></label>
            <b-row>
              <b-col>
                <b-form-input
                  v-model.lazy="dados.observacao"
                  :disabled="!editavel"
                  @change="updateOrcamentoCampo('observacao',dados.observacao, dados)"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col md="2">
            <label for=""><strong>Quantidade</strong></label>
            <b-form-input
              v-model.lazy="dados.quantidade"
              :disabled="!editavel"
              @change="updateOrcamentoCampo('quantidade',dados.quantidade, dados)"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>Valor Solicitado</strong></label>
            <b-row>
              <b-col>
                <b-form-input
                  v-money="money"
                  :value="dados.valor_solicitado "
                  :disabled="!editavel"
                  @change="updateOrcamentoCampo('valor_solicitado', removeCurrencyMask($event), dados)"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-if="infoOrcamento.tipo_orcamento === 'AVULSO'">
        <b-row class="mb-1">
          <b-col md="2">
            <label><strong>NOME</strong></label>
            <b-form-input
              v-if="!editavel"
              :value="infoOrcamento.nome ? infoOrcamento.nome : ''"
              disabled
            />
            <b-form-input
              v-else
              v-model="infoOrcamento.nome"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>FAVORECIDO</strong></label>
            <b-form-input
              v-if="!editavel"
              :value="infoOrcamento.nome_favorecido ? infoOrcamento.nome_favorecido : ''"
              disabled
            />
            <b-form-input
              v-else
              v-model="infoOrcamento.nome_favorecido"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>CHAVE PIX</strong></label>
            <b-form-input
              v-if="!editavel"
              :value="infoOrcamento.chave_pix ? infoOrcamento.chave_pix : ''"
              disabled
            />
            <b-form-input
              v-else
              v-model="infoOrcamento.chave_pix"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>AGENCIA</strong></label>
            <b-form-input
              v-if="!editavel"
              :value="infoOrcamento.agencia ? infoOrcamento.agencia : ''"
              disabled
            />
            <b-form-input
              v-else
              v-model="infoOrcamento.agencia"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>CONTA</strong></label>
            <b-form-input
              v-if="!editavel"
              :value="infoOrcamento.conta ? infoOrcamento.conta : ''"
              disabled
            />
            <b-form-input
              v-else
              v-model="infoOrcamento.conta"
            />
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row class="mb-1">
          <b-col md="2">
            <label><strong>NOME</strong></label>
            <b-form-input
              v-if="!editavel"
              :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.nome : ''"
              disabled
            />
            <b-form-input
              v-else
              v-model="infoOrcamento.prestador_servico.nome"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>CPF_CNPJ</strong></label>
            <b-form-input
              v-if="!editavel"
              :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.cpf_cnpj : ''"
              disabled
            />
            <b-form-input
              v-else
              v-model="infoOrcamento.prestador_servico.cpf_cnpj"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>CHAVE PIX</strong></label>
            <b-form-input
              v-if="!editavel"
              :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.chave_pix : ''"
              disabled
            />
            <b-form-input
              v-else
              v-model="infoOrcamento.prestador_servico.chave_pix"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>BANCO</strong></label>
            <b-form-input
              v-if="!editavel"
              :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.banco : ''"
              disabled
            />
            <b-form-input
              v-else
              v-model="infoOrcamento.prestador_servico.banco"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>AGENCIA</strong></label>
            <b-form-input
              v-if="!editavel"
              :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.agencia : ''"
              disabled
            />
            <b-form-input
              v-else
              v-model="infoOrcamento.prestador_servico.agencia"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>CONTA</strong></label>
            <b-form-input
              v-if="!editavel"
              :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.conta : ''"
              disabled
            />
            <b-form-input
              v-else
              v-model="infoOrcamento.prestador_servico.conta"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>TELEFONE</strong></label>
            <b-form-input
              v-if="!editavel"
              :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.telefone : ''"
              disabled
            />
            <b-form-input
              v-else
              v-model="infoOrcamento.prestador_servico.telefone"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>EMAIL</strong></label>
            <b-form-input
              v-if="!editavel"
              :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.email : ''"
              disabled
            />
            <b-form-input
              v-else
              v-model="infoOrcamento.prestador_servico.email"
            />
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      ref="modalViewOcamento"
      title="Detalhes Orçamento"
      size="xl"
      no-enforce-focus
    >
      <div>
        <hr>
        <b-row>
          <b-col md="4">
            <label for=""><strong>Valor Solicitado</strong></label>
            <b-form-input
              :value="infoOrcamento.valor_total_orcamento"
              readonly
            />
          </b-col>
          <b-col md="4">
            <label for=""><strong>Valor Aprovado no sistema</strong></label>
            <!-- <b-form-input
              :value="infoOrcamento.valor_total_aprovado"
              readonly
            /> -->
            <money
              :value="infoOrcamento.valor_total_aprovado"
              v-bind="money"
              class="form-control"
              disabled
            />
          </b-col>
          <b-col md="4">
            <label for=""><strong>Valor Aprovado Seguradora</strong></label>
            <!-- <b-form-input
              :value="infoOrcamento.valor_total_seguradora"
              readonly
            /> -->
            <money
              :value="infoOrcamento.valor_total_seguradora"
              v-bind="money"
              class="form-control"
              disabled
            />
          </b-col>
        </b-row>
        <hr>
        <b-row
          v-for="(dados,index) in infoOrcamento.servicos"
          :key="index"
          class="mb-1"
        >
          <b-col md="2">
            <label for=""><strong>Tipo Servico</strong></label>
            <b-form-input
              v-model="dados.tipo_servico"
              readonly
              @change="updateOrcamentoCampo('tipo_servico',dados.tipo_servico, dados)"
            />
          </b-col>
          <b-col md="3">
            <label for=""><strong>Descrição</strong></label>
            <b-form-input
              v-model.lazy="dados.descricao"
              readonly
              @change="updateOrcamentoCampo('descricao',dados.descricao, dados)"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>Observação</strong></label>
            <b-row>
              <b-col>
                <b-form-input
                  v-model.lazy="dados.observacao"
                  readonly
                  @change="updateOrcamentoCampo('observacao',dados.observacao, dados)"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col md="1">
            <label for=""><strong>Quant</strong></label>
            <b-form-input
              v-model.lazy="dados.quantidade"
              readonly
              @change="updateOrcamentoCampo('quantidade',dados.quantidade, dados)"
            />
          </b-col>
          <b-col md="2">
            <label for=""><strong>Valor Solicitado</strong></label>
            <b-row>
              <b-col>
                <money
                  :value="dados.valor_solicitado"
                  v-bind="money"
                  class="form-control"
                  readonly
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col md="2">
            <label for=""><strong>Valor Aprovado</strong></label>
            <b-row>
              <b-col>
                <money
                  :value="dados.valor_aprovado"
                  v-bind="money"
                  class="form-control"
                  disabled
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <hr>
        <div v-if="infoOrcamento.tipo_orcamento === 'AVULSO'">
          <b-row class="mb-1">
            <b-col md="2">
              <label><strong>NOME</strong></label>
              <b-form-input
                v-if="!editavel"
                readonly
                :value="infoOrcamento.nome ? infoOrcamento.nome : ''"
                disabled
              />
              <b-form-input
                v-else
                v-model="infoOrcamento.nome"
                readonly
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>FAVORECIDO</strong></label>
              <b-form-input
                v-if="!editavel"
                readonly
                :value="infoOrcamento.nome_favorecido ? infoOrcamento.nome_favorecido : ''"
                disabled
              />
              <b-form-input
                v-else
                v-model="infoOrcamento.nome_favorecido"
                readonly
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>CHAVE PIX</strong></label>
              <b-form-input
                v-if="!editavel"
                readonly
                :value="infoOrcamento.chave_pix ? infoOrcamento.chave_pix : ''"
                disabled
              />
              <b-form-input
                v-else
                v-model="infoOrcamento.chave_pix"
                readonly
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>AGENCIA</strong></label>
              <b-form-input
                v-if="!editavel"
                readonly
                :value="infoOrcamento.agencia ? infoOrcamento.agencia : ''"
                disabled
              />
              <b-form-input
                v-else
                v-model="infoOrcamento.agencia"
                readonly
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>CONTA</strong></label>
              <b-form-input
                v-if="!editavel"
                readonly
                :value="infoOrcamento.conta ? infoOrcamento.conta : ''"
                disabled
              />
              <b-form-input
                v-else
                v-model="infoOrcamento.conta"
                readonly
              />
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <b-row class="mb-1">
            <b-col md="2">
              <label><strong>NOME</strong></label>
              <b-form-input
                v-if="!editavel"
                readonly
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.nome : ''"
                disabled
              />
              <b-form-input
                v-else
                v-model="infoOrcamento.prestador_servico.nome"
                readonly
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>CPF_CNPJ</strong></label>
              <b-form-input
                v-if="!editavel"
                readonly
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.cpf_cnpj : ''"
                disabled
              />
              <b-form-input
                v-else
                v-model="infoOrcamento.prestador_servico.cpf_cnpj"
                readonly
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>CHAVE PIX</strong></label>
              <b-form-input
                v-if="!editavel"
                readonly
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.chave_pix : ''"
                disabled
              />
              <b-form-input
                v-else
                v-model="infoOrcamento.prestador_servico.chave_pix"
                readonly
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>BANCO</strong></label>
              <b-form-input
                v-if="!editavel"
                readonly
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.banco : ''"
                disabled
              />
              <b-form-input
                v-else
                v-model="infoOrcamento.prestador_servico.banco"
                readonly
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>AGENCIA</strong></label>
              <b-form-input
                v-if="!editavel"
                readonly
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.agencia : ''"
                disabled
              />
              <b-form-input
                v-else
                v-model="infoOrcamento.prestador_servico.agencia"
                readonly
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>CONTA</strong></label>
              <b-form-input
                v-if="!editavel"
                readonly
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.conta : ''"
                disabled
              />
              <b-form-input
                v-else
                v-model="infoOrcamento.prestador_servico.conta"
                readonly
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>TELEFONE</strong></label>
              <b-form-input
                v-if="!editavel"
                readonly
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.telefone : ''"
                disabled
              />
              <b-form-input
                v-else
                v-model="infoOrcamento.prestador_servico.telefone"
                readonly
              />
            </b-col>
            <b-col md="3">
              <label for=""><strong>EMAIL</strong></label>
              <b-form-input
                v-if="!editavel"
                readonly
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.email : ''"
                disabled
              />
              <b-form-input
                v-else
                v-model="infoOrcamento.prestador_servico.email"
                readonly
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button
          v-if="infoOrcamento.status !== 'CANCELADO'"
          size="sm"
          variant="danger"
          @click="cancelarOrcamento(hide)"
        >
          Cancelar Orçamento
        </b-button>
        <div />
      </template>
    </b-modal>
    <b-modal
      ref="confirmEsorno"
      title="Confirmar Estorno"
      cancel-title="Cancelar"
      cancel-variant="danger"
      size="xl"
      @hidden="resetMOdalEstorno"
    >
      <b-row>
        <b-col
          v-for="orcamento in orcamentosSelectedEstorno"
          :key="orcamento.orcamentoId"
        >
          <b-form-group>
            <!-- {{ estornoOrcamentoServices }} -->
            <h4><strong>{{ orcamento.favorecido }}</strong> - <span>{{ orcamento.valorOrcamento.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</span></h4>
            <b-form-checkbox
              v-for="option in estornoOrcamentoServices[orcamento.orcamentoId]"
              :key="`${option.text} ${orcamento.orcamentoId}`"
              v-model="selectedServicoEstorno"
              :value="option.value"
              name="flavour-3a"
              class="mt-1"
            >
              {{ option.text }}
            </b-form-checkbox>
          </b-form-group>

          <hr>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col md="4  ">
          <label
            for=""
            class="mb-1"
          >Selecione o arquivo:</label>
          <b-form-file
            v-model="anexoEstorno"
            placeholder=""
            drop-placeholder="Arquivo"
          />
        </b-col>

        <b-col md="2  ">
          <label
            for=""
            class="mb-1"
          >Valor estornado:</label>
          <money
            v-model="valorAvulso"
            v-bind="money"
            class="form-control"
          />
        </b-col></b-row>

      <template
        #modal-footer="{ cancel, hide }"
      >
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right ml-2"
            @click="confirmEstorno(hide)"
          >
            Enviar
          </b-button>

          <b-button
            variant="outline-secondary"
            class="float-right"
            @click="cancel()"
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BButton, BModal, BFormInput, BFormGroup, BInputGroup, BFormTextarea, BBadge, BForm, BOverlay, BFormSelect, VBTooltip,
  BAlert,
  BFormCheckbox,
  // BFormCheckboxGroup,
  BFormFile,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

import { heightTransition } from '@core/mixins/ui/transition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import md5 from 'crypto-js/md5'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import { Money, VMoney } from 'v-money'

// eslint-disable-next-line import/no-extraneous-dependencies

import axios from '../../../../../axios-auth'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
    BPagination,
    BModal,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BBadge,
    BForm,
    vSelect,
    BOverlay,
    BFormSelect,
    BAlert,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    Money,
    ValidationObserver,
    ValidationProvider,
    // BFormCheckboxGroup,
    BFormFile,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    money: VMoney,
  },
  mixins: [heightTransition],
  data() {
    return {
      selectedServicoEstorno: [], // Must be an array reference!
      optionsServices: [
        { text: 'Orange', value: 'orange' },
        { text: 'Apple', value: 'apple' },
        { text: 'Pineapple', value: 'pineapple' },
        { text: 'Grape', value: 'grape' },
      ],
      price: 123.45,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },

      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      selected: { title: 'Selecione' },
      selected2: { title: 'Selecione o Técnico' },
      selected3: { title: 'Normal' },
      selected1: {
        title: 'Aperture',
        icon: 'ApertureIcon',
      },
      optionsTipoServico: [
        'VISITA',
        'MÃO DE OBRA',
        'PEÇAS',
        'OUTROS',
      ],
      optionsVisitaSelected: false,
      option2: [{ title: 'Tecnico 1' }, { title: 'Tecnico 2' }, { title: 'Tecnico 3' }, { title: 'Tecnico 4' }],
      option3: [{ title: 'Normal' }, { title: 'Avulso' }, { title: 'Internet' }],
      fields: [
        { key: 'selecionar', label: 'Selecionar' },
        { key: 'acoes', label: 'AÇÕES' },
        { key: 'status', label: 'Status' },
        { key: 'tipo_orcamento', label: 'TIPO ORÇAMENTO' },
        { key: 'created_at', label: 'DATA' },
        { key: 'prestador_servico.nome', label: 'TECNICO/PRESTADOR' },
        { key: 'valor_total_orcamento', label: 'Valor' },
        { key: 'valor_total_aprovado', label: 'Valor Aprovado' },
      ],
      items: [],
      items2: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
      empresaID: null,
      optionsPrestador: [],
      prestadorSelected: [],
      servicoSelected: [],
      servicoDescricao: [],
      valorSolicitado: { 0: 0 },
      temp: null,
      servicoQuantidade: [],
      orcamentoTotal: 0,
      nomeFavorecido: '',
      nomeServico: '',
      agencia: '',
      conta: '',
      chavePix: '',
      valorAvulso: 0,
      descricaoServicoAvulso: '',
      load: true,
      infoOrcamento: {},
      editavel: false,
      orcamentoServicoUpdate: [],
      newValorOrcamentoServico: 0,
      orcamentoID: null,
      editRevisao: false,
      editTecnico: null,
      required,
      email,
      linkOrcamentoInternet: '',
      linhaDigitavelOrcamentoInternet: '',
      selectOrcamento: false,
      orcamentosSelectedEstorno: [],
      anexoEstorno: null,
      valorEstornado: 0,
    }
  },
  computed: {
    estornoOrcamentoServices() {
      const orcamentos = {}
      this.orcamentosSelectedEstorno.forEach(item => {
        orcamentos[item.orcamentoId] = item.servicos.map(servico => ({
          text: `${servico.descricao} - ${servico.valor_aprovado}`,
          value: {
            orcamento_id: item.orcamentoId,
            servico,
          },
        }))
      })

      return orcamentos
    },
    estornoFiltered() {
      const itemsEstorno = this.items.filter(item => item.status === 'ESTORNO - PENDENTE')

      return itemsEstorno
    },
    haveEstorno() {
      if (this.estornoFiltered.length > 0 && !this.selectOrcamento) {
        return true
      }
      return false
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },

    previewValorOcamento() {
      let sum = 0
      const { valorSolicitado } = this
      Object.keys(valorSolicitado).map(key => {
        sum += valorSolicitado[key]
      })
      this.orcamentoTotal = sum
      return sum.toFixed(2)
    },

    prestadorAvaible() {
      // eslint-disable-next-line no-unused-vars
      const { agencia, conta, chave_pix: chavePix } = this.prestadorSelected
      if ((!agencia && !conta) && !chavePix && this.selected3.title !== 'Avulso' && this.selected3.title !== 'Internet') {
        return false
      }
      return true
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  created() {
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    this.getOrcamentos()
  },
  methods: {
    resetMOdalEstorno() {
      this.estornoOrcamentoServices = {}
      this.orcamentosSelectedEstorno = []
    },
    selectOrcamentoEstorno(data) {
      const { state, item } = data

      if (state) {
        this.orcamentosSelectedEstorno = [
          ...this.orcamentosSelectedEstorno,
          {
            orcamentoId: item.id,
            servicos: item.servicos,
            favorecido: item.prestador_servico.nome || item.nome_favorecido,
            valorOrcamento: item.valor_total_aprovado,
          },
        ]
      } else {
        this.orcamentosSelectedEstorno = this.orcamentosSelectedEstorno.filter(e => e.orcamentoId !== item.id)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async confirmEstorno(hide) {
      // console.log(this.selectedServicoEstorno)
      const formData = new FormData()
      formData.append('comprovante', this.anexoEstorno)
      formData.append('orcamentoServicos', JSON.stringify(this.selectedServicoEstorno))
      formData.append('valorEstornado', this.valorEstornado)
      formData.append('ordem_servico_id', this.$route.params.id)
      const { data } = await axios.post('api/orcamento/confirm-estorno', formData)
      this.temp = data
    },
    cancelSelectOrcamentoEstorno() {
      this.orcamentosSelectedEstorno = []
      this.selectOrcamento = false
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    repeateAgain() {
      const selectedService = this.servicoSelected[this.servicoSelected.length - 1]
      this.items2.push({
        servico: selectedService,
        id: this.nextTodoId += this.nextTodoId,
      })
      const index = this.optionsTipoServico.indexOf('VISITA')
      if (this.servicoSelected.length <= 1) {
        if (index > -1) {
          this.optionsTipoServico.splice(index, 1)
        }
      }
    },
    removeItem(index) {
      this.items2.splice(index, 1)
      if (this.servicoSelected.length >= 1 && !this.optionsTipoServico.includes('VISITA')) {
        this.optionsTipoServico.unshift('VISITA')
      }
    },
    getOrcamentos() {
      this.items = []
      axios
        .get(`api/orcamento/listar/${this.$route.params.id}`)
        .then(res => {
          this.items = [...res.data.dados]
          this.load = false
        })
    },
    async openModalOrcamento() {
      await this.getPrestadores()
      this.$refs.modalOrcamento.show()
    },
    getPrestadores() {
      this.optionsPrestador = []
      axios
        .get(`api/prestador_servico/${this.empresaID}`)
        .then(res => {
          res.data.dados.map(item => {
            this.optionsPrestador.push({
              label: item.nome,
              id: item.id,
              agencia: item.agencia,
              conta: item.conta,
              chave_pix: item.chave_pix,
            })
          })
        })
    },
    // eslint-disable-next-line consistent-return
    async storeNewOrcamento(hide) {
      this.load = true
      const body = {}
      body.orcamento = {}
      body.orcamento.ordem_de_servico_id = parseInt(this.$route.params.id)
      body.orcamento.atendente_id = JSON.parse(localStorage.getItem('userId'))

      let ready = true

      if (this.selected3.title === 'Internet') {
        // eslint-disable-next-line consistent-return
        const success = await this.$refs['orcamento-internet-validation'].validate()
        if (!success) {
          ready = false
        }
        body.orcamento.tipo_orcamento = 'INTERNET'
        body.orcamento.valor_total_orcamento = this.removeCurrencyMask(this.valorAvulso)
        body.orcamento.link = this.linkOrcamentoInternet
        body.orcamento.linha_digitavel_boleto = this.linkOrcamentoInternet
        body.orcamento.descricao = this.descricaoServicoAvulso
      } else if (this.selected3.title === 'Normal') {
        body.orcamento.prestador_servico_id = this.prestadorSelected.id
        body.orcamento.tipo_orcamento = 'NORMAL'
        body.orcamento.valor_total_orcamento = this.orcamentoTotal

        body.servico = []
        for (let i = 0; i < this.servicoDescricao.length; i++) {
          const servicoObj = {}
          servicoObj.descricao = this.servicoDescricao[i]
          servicoObj.valor_solicitado = this.valorSolicitado[i]
          servicoObj.tipo_servico = this.servicoSelected[i]
          this.servicoQuantidade[i] ? servicoObj.quantidade = this.servicoQuantidade[i] : servicoObj.quantidade = 1
          body.servico.push(servicoObj)
        }
      } else if (this.selected3.title === 'Avulso') {
        body.orcamento.tipo_orcamento = 'AVULSO'
        body.orcamento.valor_total_orcamento = this.valorAvulso
        body.orcamento.nome_favorecido = this.nomeFavorecido.toUpperCase()
        body.orcamento.nome = this.nomeServico.toUpperCase()
        body.orcamento.agencia = this.agencia
        body.orcamento.conta = this.conta
        body.orcamento.chave_pix = this.chavePix
        body.orcamento.descricao = this.descricaoServicoAvulso
      }

      if (!ready) {
        return null
      }

      axios
        .post('api/orcamento/store', body)
        .then(() => {
          this.getOrcamentos()
          this.clearFormNovoOrcamento()
          this.$swal({
            icon: 'success',
            title: 'Cadastrado!',
            text: 'Orcamento cadastrado com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })

          hide()
        })
        .catch(error => {
          this.getOrcamentos()
          this.clearFormNovoOrcamento()
          if (error.response) {
            this.$swal({
              title: 'Erro ao cadastrar um orcamento!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    clearFormNovoOrcamento() {
      this.prestadorSelected = []
      this.servicoSelected = []
      this.servicoDescricao = []
      this.valorSolicitado = { 0: 0 }
      this.servicoQuantidade = [1]
      this.orcamentoTotal = 0
      this.nomeFavorecido = ''
      this.nomeServico = ''
      this.agencia = ''
      this.conta = ''
      this.chavePix = ''
      this.valorAvulso = 0
      this.descricaoServicoAvulso = ''
      this.linkOrcamentoInternet = ''
      this.linhaDigitavelOrcamentoInternet = ''
    },
    async openModalEditOrcamento(id, status) {
      const response = await axios.get(`api/orcamento/edit/${id}`)
      const { dados } = response.data
      this.infoOrcamento = dados
      this.orcamentoID = dados.id
      dados.tipo_orcamento === 'AVULSO' ? this.editTecnico = false : this.editTecnico = true
      status === 'REVISÃO' || status === 'REVISÃO BANCÁRIA' ? this.editRevisao = true : this.editRevisao = false
      this.editavel = true
      this.$refs.modalInforOcamento.show()
    },
    async openModalViewOrcamento(id, status) {
      const response = await axios.get(`api/orcamento/edit/${id}`)
      const { dados } = response.data
      this.infoOrcamento = dados
      this.orcamentoID = dados.id
      dados.tipo_orcamento === 'AVULSO' ? this.editTecnico = false : this.editTecnico = true
      status === 'REVISÃO' || status === 'REVISÃO BANCÁRIA' ? this.editRevisao = true : this.editRevisao = false
      this.editavel = true
      this.$refs.modalViewOcamento.show()
    },
    updateOrcamentoCampo(campo, valor, dados) {
      const servicosAntesDaAtualizacao = JSON.parse(JSON.stringify(this.infoOrcamento.servicos))
      let updateServico = this.orcamentoServicoUpdate.find(s => s.servico_id === dados.id)
      if (!updateServico) {
        updateServico = {
          servico: {},
          servico_id: dados.id,
        }
        this.orcamentoServicoUpdate.push(updateServico)
      }
      updateServico.servico[campo] = valor

      const servicosNaoAtualizados = servicosAntesDaAtualizacao.filter(servico => servico.id !== dados.id)
      const servicosAtualizados = [...this.infoOrcamento.servicos.filter(servico => servico.id === dados.id), ...this.orcamentoServicoUpdate.filter(servico => servico.servico_id === dados.id)]

      const valorTotalNaoAtualizados = servicosNaoAtualizados.reduce((acc, cur) => acc + parseFloat(cur.valor_solicitado || 0), 0)
      const valorTotalAtualizados = servicosAtualizados.reduce((acc, cur) => acc + parseFloat(cur.valor_solicitado || 0), 0)

      this.newValorOrcamentoServico = valorTotalNaoAtualizados + valorTotalAtualizados
      this.orcamentoTotal = this.newValorOrcamentoServico
    },
    storeUpdateOrcamento() {
      const body = {
        valor_total: this.newValorOrcamentoServico,
        servicoes: this.orcamentoServicoUpdate,
        status_revisao: this.editRevisao,
      }
      if (this.editTecnico) {
        if (this.infoOrcamento.prestador_servico && this.infoOrcamento.prestador_servico.nome) {
          body.prestador_servico = {
            nome: this.infoOrcamento.prestador_servico.nome,
          }
        }
        body.prestador_servico.cpf_cnpj = this.infoOrcamento.prestador_servico.cpf_cnpj
        body.prestador_servico.chave_pix = this.infoOrcamento.prestador_servico.chave_pix
        body.prestador_servico.banco = this.infoOrcamento.prestador_servico.banco
        body.prestador_servico.agencia = this.infoOrcamento.prestador_servico.agencia
        body.prestador_servico.conta = this.infoOrcamento.prestador_servico.conta
        body.prestador_servico.telefone = this.infoOrcamento.prestador_servico.telefone
        body.prestador_servico.email = this.infoOrcamento.prestador_servico.email
      } else {
        if (this.infoOrcamento.nome) {
          body.avulso = {
            nome: this.infoOrcamento.nome,
          }
        }
        this.infoOrcamento.nome ? body.avulso.nome = this.infoOrcamento.nome : null
        this.infoOrcamento.nome_favorecido ? body.avulso.nome_favorecido = this.infoOrcamento.nome_favorecido : null
        this.infoOrcamento.chave_pix ? body.avulso.chave_pix = this.infoOrcamento.chave_pix : null
        this.infoOrcamento.agencia ? body.avulso.agencia = this.infoOrcamento.agencia : null
        this.infoOrcamento.conta ? body.avulso.conta = this.infoOrcamento.conta : null
      }
      axios
        .put(`api/orcamento/update/${this.orcamentoID}`, body)
        .then(() => {
          this.orcamentoServicoUpdate = []
          this.newValorOrcamentoServico = 0
          this.orcamentoID = null
          this.status_revisao = null
          this.editTecnico = null
          this.getOrcamentos()
          this.$swal({
            icon: 'success',
            title: 'Atualizado!',
            text: 'Orcamento atualizado com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          this.orcamentoServicoUpdate = []
          this.newValorOrcamentoServico = 0
          this.orcamentoID = null
          this.status_revisao = null
          this.editTecnico = null
          this.getOrcamentos()
          if (error.response) {
            this.$swal({
              title: 'Erro ao atualizar!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    copiarLink(id) {
      const stringID = id.toString()
      try {
        const hash = md5(stringID).toString()
        const url = `http://vssyst.com/assinar/${hash}`
        const textarea = document.createElement('textarea')
        textarea.value = url
        textarea.setAttribute('readonly', '')
        textarea.style.position = 'absolute'
        textarea.style.left = '-9999px'
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copiado',
            icon: 'CheckIcon',
            text: 'Copiado com sucesso',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'erro',
            icon: 'XIcon',
            text: `Erro ao copiar ${error}`,
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },

    async cancelarOrcamento(hide) {
      const { value: description } = await this.$swal.fire({
        title: 'Descrição do cancelamento',
        input: 'text',
        inputPlaceholder: '',
      })

      if (description) {
        this.$swal.fire({
          title: 'ATENÇÃO',
          text: 'Deseja cancelar orçamento?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, confirmo!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            axios.post(`api/orcamento/destroy/${this.orcamentoID}`, {
              description: description.toUpperCase(),
            }, {
              headers: {
                Accept: 'application/pdf',
              },
            })
              .then(() => {
                this.orcamentoServicoUpdate = []
                this.newValorOrcamentoServico = 0
                this.orcamentoID = null
                this.status_revisao = null
                this.editTecnico = null
                this.getOrcamentos()
                hide()
                this.$swal({
                  icon: 'success',
                  title: 'Atualizado!',
                  text: 'Orcamento atualizado com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
              .catch(error => {
                if (error.response) {
                  this.$swal({
                    title: 'ERRO!',
                    icon: 'error',
                    text: `Ocorreu um erro: ${error.response.data.mensagem} `,
                    customClass: {
                      confimButton: 'btn btn-danger',
                    },
                  })
                }
              })
          }
        })
      }
    },

    removeCurrencyMask(valor) {
      const withwoutMark = valor.replace('R$', '')

      const toFloat = withwoutMark.replace('.', '').replace(',', '.')

      return Number(toFloat) || 0
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.nova-entidade {
  float: right;
  margin-right: 5px;
}

.nova-entidade:hover {
  color: blueviolet;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
