<template>
  <b-card>
    <b-tabs>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="AlignCenterIcon" />
          <span>Histórico</span>
        </template>
        <o-s-tab-historico :reload="reload" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="AlertCircleIcon" />
          <span>Informações</span>
        </template>
        <o-s-tab-informacoes
          :info="informacoes[0]"
          :reload="reload"
        />
      </b-tab>
      <b-tab active>
        <template #title>
          <feather-icon icon="CalendarIcon" />
          <span>Agendamento</span>
        </template>
        <o-s-tab-agendamento :reload="reload" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ExternalLinkIcon" />
          <span>Orçamento</span>
        </template>
        <o-s-tab-orcamento-cad /></b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ArchiveIcon" />
          <span>Arquivos</span>
        </template>
        <o-s-tab-arquivos />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" />
          <span>Financeiro</span>
        </template>

        <o-s-tab-financeiro />
      </b-tab>
    </b-tabs>
  </b-card>

</template>

<script>
import { BTabs, BTab, BCard } from 'bootstrap-vue'
import OSTabHistorico from './tabsCard/OSTabHistoricoCard.vue'
import OSTabInformacoes from './tabsCard/OSTabInformacoes.vue'
import OSTabAgendamento from './tabsCard/OSTabAgendamento.vue'
import OSTabArquivos from './tabsCard/OSTabArquivosCard.vue'
import OSTabOrcamentoCad from './tabsCard/OSTabOrcamentoCad.vue'
import OSTabFinanceiro from './tabsCard/OSTabFinanceiro.vue'

export default {
  components: {
    OSTabAgendamento,
    BTabs,
    BTab,
    BCard,
    OSTabHistorico,
    OSTabInformacoes,
    OSTabArquivos,
    OSTabOrcamentoCad,
    OSTabFinanceiro,
  },
  props: {
    informacoes: {
      type: Array,
      required: true,
    },
    reload: {
      type: Function,
      required: true,
    },
  },
}
</script>
