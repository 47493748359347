<template>
  <b-row>
    <b-modal
      id="modal-agendamento"
      ref="modalAgendamento"
      cancel-variant="outline-secondary"
      ok-title="Agendar"
      cancel-title="Cancelar"
      title="Novo Agendamento"
      @ok="storeAgendamento"
    >

      <b-col md="12">
        <b-form-group>
          <label class="d-inline">Técnico/Prestador <span
            class="nova-entidade"
            @click="cadastrarNovoPrestador()"
          >+ Add Novo</span></label>
          <v-select
            v-model="prestadorSelected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionsPrestador"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group>
          <label class="d-inline">Data</label>
          <flat-pickr
            v-model="dateDefault"
            class="form-control"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group>
          <label class="d-inline">Período</label>
          <v-select
            v-model="periodoSelected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionsPeriodo"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <!-- textarea -->
        <b-input-group prepend="Obs:">
          <b-form-textarea v-model="observacao" />
        </b-input-group>
      </b-col>
    </b-modal>
    <b-modal
      ref="modalNovoPrestador"
      title="Cadastrar Novo Prestador"
      ok-title="Cadastrar"
      cancel-title="Cancelar"
      cancel-variant="danger"
      @ok="storeNovoPrestador"
    >
      <b-form>
        <b-row>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="*Nome"
              label-for="name"
            >
              <b-form-input
                id="customer-name"
                v-model="nome"
                trim
                placeholder="Nome"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Favorecido"
              label-for="favorecido"
            >
              <b-form-input
                id="customer-name"
                v-model="favorecido"
                trim
                placeholder="Favorecido"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="*CPF/CNPJ"
              label-for="CPF/CNPJ"
            >
              <cleave
                v-if="cpf_cnpj.length > 14"
                id="customDelimiter"
                v-model="cpf_cnpj"
                class="form-control"
                :raw="false"
                :options="{
                  numericOnly: true, ...mask.cnpj
                }"
                placeholder="CPF/CNPJ"
              />
              <cleave
                v-else
                id="customDelimiter"
                v-model="cpf_cnpj"
                class="form-control"
                :raw="false"
                :options="{
                  numericOnly: true, ...mask.cpf
                }"
                placeholder="CPF/CNPJ"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Banco"
              label-for="banco"
            >
              <b-form-input
                id="banco"
                v-model="banco"
                trim
                placeholder="Banco"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Agência"
              label-for="agencia"
            >
              <b-form-input
                id="Agência"
                v-model="agencia"
                trim
                placeholder="Agência"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Conta"
              label-for="conta"
            >
              <b-form-input
                id="Conta"
                v-model="conta"
                trim
                placeholder="Conta"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="PIX"
              label-for="chave_pix"
            >
              <b-form-input
                id="chave_pix"
                v-model="chave_pix"
                trim
                placeholder="PIX"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Endereco"
              label-for="endereco"
            >
              <b-form-input
                id="endereco"
                v-model="endereco"
                placeholder="Endereço"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="CEP"
              label-for="endereco"
            >
              <b-input-group>
                <cleave
                  v-model="cep"
                  name="cep"
                  class="form-control"
                  :raw="false"
                  :options="{numericOnly: true, ...mask.cep}"
                  placeholder="00000-000"
                  @keyup.enter="consultaCEP()"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="consultaCEP()"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Numero"
              label-for="endereco"
            >
              <b-form-input
                id="endereco"
                v-model="numero"
                type="number"
                placeholder="Número"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="UF"
              label-for="endereco"
            >
              <b-form-input
                id="endereco"
                v-model="uf"
                placeholder="UF"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="BAIRRO"
              label-for="endereco"
            >
              <b-form-input
                id="BAIRRO"
                v-model="bairro"
                placeholder="Bairro"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Cidade"
              label-for="country"
            >
              <b-form-input
                id="endereco"
                v-model="cidade"
                placeholder="Cidade"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Telefone"
              label-for="telefone"
            >
              <b-form-input
                id="telefone"
                v-model="telefone"
                placeholder="Telefone"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                placeholder="Email"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Assistência"
              label-for="Assistência"
            >
              <b-form-input
                id="assistencia"
                v-model="assistencia"
                placeholder="Assistência"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col>
            <label>Linhas</label>
            <v-select
              v-model="selectedLinhas"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsLinhas"
              :clearable="false"
              class="mb-2 item-selector-title"
              placeholder="Selecionar Linhas"
              multiple
              :close-on-select="false"
            />
          </b-col>
          <b-col>
            <label>Cidades</label>
            <v-select
              v-model="selectedCidades"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsCidades"
              :clearable="false"
              class="mb-2 item-selector-title"
              placeholder="Selecionar cidades"
              multiple
              :close-on-select="false"
            />
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      ref="modalReagendamento"
      title="Reagendamento"
      ok-title="Reagendar"
      cancel-title="Cancelar"
      @ok="newReagendamento"
    >
      <b-col md="12">
        <b-form-group>
          <label class="d-inline">Data</label>
          <flat-pickr
            v-model="dateReagendamento"
            class="form-control"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group>
          <label class="d-inline">Período</label>
          <v-select
            v-model="periodoSelected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionsPeriodo"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-input-group prepend="Obs:">
          <b-form-textarea v-model="observacaoReagendamento" />
        </b-input-group>
      </b-col>
    </b-modal>
    <b-modal
      ref="modalTransferencia"
      title="Transferir prestador"
      ok-title="Transferir"
      cancel-title="Cancelar"
      cancel-variant="danger"
      :ok-disabled="observacaoTransferencia === ''"
      @ok="transferirPrestador"
    >
      <b-col md="12">
        <b-form-group>
          <label class="d-inline">Técnico/Prestador <span
            class="nova-entidade"
            @click="cadastrarNovoPrestador()"
          >+ Add Novo</span></label>
          <v-select
            v-model="prestadorSelected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionsPrestador"
            class="mb-1"
          />
          <b-input-group prepend="Obs:">
            <b-form-textarea v-model="observacaoTransferencia" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-modal>
    <b-modal
      ref="modalPrevisaoReparo"
      title="Previsão de Reparo"
      cancel-title="Cancelar"
      cancel-variant="danger"
      @ok="newPrevisaoReparo"
    >
      <b-col md="12">
        <b-form-group>
          <label class="d-inline">Data</label>
          <flat-pickr
            v-model="dataPrevisaoReparo"
            class="form-control"
          />
        </b-form-group>
      </b-col>
      <b-input-group prepend="Obs:">
        <b-form-textarea v-model="observacaoPrevisaoReparo" />
      </b-input-group>
    </b-modal>
    <b-modal
      ref="modalVincularTecnico"
      title="Vincular técnico"
      cancel-title="Cancelar"
      cancel-variant="danger"
      ok-title="Vincular"
      @ok="storeVinculoTecnico"
    >
      <b-col md="12">
        <b-form-group>
          <label class="d-inline">Técnico/Prestador <span
            class="nova-entidade"
            @click="cadastrarNovoPrestador()"
          >+ Add Novo</span></label>
          <v-select
            v-model="prestadorSelected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionsPrestador"
          />
        </b-form-group>
      </b-col>
    </b-modal>
    <b-col
      md="12"
      class="my-1"
    >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="float-right"
        variant="outline-primary"
        size="sm"
        :disabled="items.length > 0"
        @click="openModalAgendamento"
      >
        <feather-icon
          icon="PlusCircleIcon"
          class="mr-50"
        />
        <span class="align-middle">Novo Agendamento</span>
      </b-button>
    </b-col>

    <b-col cols="12">
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <b-table
          striped
          hover
          responsivef
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(status)="data">
            <b-badge :variant="resolveBadgeVariantStatus(data.item.status)">
              {{ data.item.status }}
            </b-badge>
          </template>
          <template #cell(data)="data">
            {{ data.item.data ? formatTimezone(data.item.data) : '' }}
          </template>
          <template #cell(previsao_reparo)="data">
            {{ data.item.previsao_reparo ? formatTimezone(data.item.previsao_reparo) : '' }}
          </template>
          <template #cell(acoes)="data">
            <b-row>
              <b-col>
                <feather-icon
                  v-b-tooltip.hover.top="'Reagendar'"
                  class="plus-icon"
                  size="16"
                  icon="ClockIcon"
                  @click="openModalReagendamento(data.item)"
                />
              </b-col>
              <b-col>
                <feather-icon
                  v-b-tooltip.hover.top="'Transferir prestador'"
                  class="plus-icon"
                  size="16"
                  icon="RepeatIcon"
                  @click="openModalTransferencia(data.item.id)"
                />
              </b-col>
              <b-col v-if="data.item.ordem_servico.status_os_id !== 12">
                <feather-icon
                  v-b-tooltip.hover.top="'Laudo'"
                  class="plus-icon"
                  size="16"
                  icon="ClipboardIcon"
                  @click="gerarLaudo(data.item)"
                />
              </b-col>
              <b-col>
                <feather-icon
                  v-show="!data.item.previsao_reparo"
                  v-b-tooltip.hover.top="'Previsão de reparo'"
                  class="color-icon"
                  size="16"
                  icon="ToolIcon"
                  @click="openModalPrevisaoReparo(data.item.id)"
                />
              </b-col>
              <b-col>
                <feather-icon
                  v-b-tooltip.hover.top="'Copiar Link'"
                  class="color-icon"
                  size="16"
                  icon="Share2Icon"
                  @click="copiarLink()"
                />
              </b-col>
              <b-col>
                <feather-icon
                  v-show="!data.item.prestador_servico"
                  v-b-tooltip.hover.top="'Vincular Técnico'"
                  class="color-icon"
                  size="16"
                  icon="ActivityIcon"
                  @click="vincularTecnico(data.item.id)"
                />
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-overlay>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BButton, BModal, BFormGroup, BInputGroup, BFormTextarea, BBadge,
  BForm, BFormInput, BInputGroupAppend, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import md5 from 'crypto-js/md5'
import axios from '../../../../../axios-auth'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
    BPagination,
    BModal,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    flatPickr,
    vSelect,
    BBadge,
    BForm,
    BFormInput,
    Cleave,
    BInputGroupAppend,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    reload: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'id', label: 'Identificador', class: 'text-center' },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'data', label: 'Data', sortable: true },
        { key: 'periodo', label: 'periodo', sortable: true },
        { key: 'prestador_servico.nome', label: 'Tecnico/PRESTADOR', sortable: true },
        { key: 'previsao_reparo', label: 'Previsao de Reparo', sortable: true },
        { key: 'acoes', label: 'Ações', class: 'd-flex' },
      ],
      items: [],
      periodoSelected: '',
      optionsPrestador: [],
      prestadorSelected: [],
      optionsPeriodo: [
        'MANHA',
        'TARDE',
        'NOITE',
      ],
      dateDefault: null,
      mask: {
        cep: {
          delimiters: ['-'],
          blocks: [5, 3],
        },
        cnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
        cpf: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 3],
          uppercase: true,
        },
      },
      nome: '',
      endereco: '',
      cep: '',
      bairro: '',
      cidade: '',
      telefone: '',
      email: '',
      uf: '',
      numero: '',
      cpf_cnpj: '',
      chave_pix: '',
      banco: '',
      conta: '',
      agencia: '',
      empresaID: null,
      observacao: '',
      agendamentoID: null,
      observacaoReagendamento: '',
      dateReagendamento: null,
      observacaoTransferencia: '',
      dataPrevisaoReparo: '',
      observacaoPrevisaoReparo: '',
      endpoint: process.env.VUE_APP_ROOT_API,
      favorecido: '',
      load: true,
      assistencia: '',
      optionsLinhas: [],
      selectedLinhas: [],
      optionsCidades: [],
      selectedCidades: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    this.getAgendamentos()
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async openModalAgendamento() {
      await this.getPrestadores()
      this.$refs.modalAgendamento.show()
    },
    async cadastrarNovoPrestador() {
      await this.getLinhas()
      await this.getCidadesList()
      this.$refs.modalNovoPrestador.show()
    },
    async openModalTransferencia(id) {
      this.agendamentoID = id
      await this.getPrestadores()
      this.$refs.modalTransferencia.show()
    },
    getPrestadores() {
      this.optionsPrestador = []
      axios
        .get(`api/prestador_servico/${this.empresaID}`)
        .then(res => {
          res.data.dados.map(item => {
            this.optionsPrestador.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    clearNovoPrestador() {
      this.nome = null
      this.endereco = null
      this.cep = null
      this.bairro = null
      this.cidade = null
      this.telefone = null
      this.email = null
      this.uf = null
      this.numero = null
      this.cpf_cnpj = ''
      this.favorecido = ''
      this.assistencia = ''
      this.selectedLinhas = []
      this.selectedCidades = []
    },
    storeNovoPrestador() {
      if (!this.nome || !this.cpf_cnpj) {
        this.$swal({
          title: 'Erro ao cadastrar um prestador!',
          text: 'Campos Obrigatórios não informado: nome e cpf',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      const body = {
        empresa_id: this.empresaID,
        nome: this.nome,
        cpf_cnpj: this.soNumero(this.cpf_cnpj),
        banco: this.banco,
        agencia: this.agencia,
        conta: this.conta,
        chave_pix: this.chave_pix,
      }
      this.endereco.length ? body.endereco = this.endereco : null
      this.cep.length ? body.cep = this.soNumero(this.cep) : null
      this.bairro.length ? body.bairro = this.bairro : null
      this.cidade.length ? body.cidade = this.cidade : null
      this.telefone.length ? body.telefone = this.soNumero(this.telefone) : null
      this.email.length ? body.email = this.email : null
      this.uf.length ? body.uf = this.uf : null
      this.numero.length ? body.numero = this.numero : null
      this.favorecido.length ? body.favorecido = this.favorecido : null
      this.assistencia.length ? body.assistencia = this.assistencia : null
      this.selectedLinhas.length > 0 ? body.linhas_prestador = this.selectedLinhas.map(item => item.id) : null
      this.selectedCidades.length > 0 ? body.cidades_prestador = this.selectedCidades.map(item => item.id) : null

      axios
        .post('api/prestador_servico/store', body)
        .then(() => {
          this.clearNovoPrestador()
          this.getPrestadores()
          this.$refs.modalNovoPrestador.hide()
          this.$swal({
            icon: 'success',
            title: 'Cadastrado!',
            text: 'Prestador cadastrado com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          if (error.response) {
            this.$swal({
              title: 'Erro ao cadastrar um prestador!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    async consultaCEP() {
      if (this.cep) {
        if (this.cep.replace(/[^0-9]/g, '').length === 8) {
          await axios
            .get(`https://viacep.com.br/ws/${this.cep}/json/`)
            .then(async res => {
              const { data } = res
              if (data.erro) {
                this.$swal({
                  title: 'ERROR',
                  text: 'CEP não encontrado',
                  icon: 'error',
                  customClass: {
                    comfirmButton: 'btn btn-danger',
                  },
                })
              } else {
                this.endereco = data.logradouro
                this.bairro = data.bairro
                this.cidade = data.localidade
                this.uf = data.uf
              }
            })
            .catch(() => {
              this.$swal({
                title: 'ERROR',
                text: 'CEP não encontrado',
                icon: 'error',
                customClass: {
                  comfirmButton: 'btn btn-danger',
                },
              })
            })
        }
      }
      return null
    },
    soNumero(valor) {
      return valor.replace(/\D/g, '')
    },
    storeAgendamento() {
      this.load = true
      const body = {
        data: this.dateDefault,
        periodo: this.periodoSelected,
        ordem_de_servico_id: this.$route.params.id,
      }
      this.prestadorSelected ? body.prestador_servico_id = this.prestadorSelected.id : null
      this.observacao ? body.observacao = this.observacao.toUpperCase() : null
      axios
        .post('api/agendamento/store', body)
        .then(() => {
          this.reload()
          this.getAgendamentos()
          this.clearFormAgendamentos()
          this.$swal({
            icon: 'success',
            title: 'Agendado!',
            text: 'Agendamento feito com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          this.getAgendamentos()
          this.clearFormAgendamentos()
          if (error.response) {
            this.$swal({
              title: 'Erro ao realizar um agendamento!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    getAgendamentos() {
      this.items = []
      axios
        .get(`api/agendamento/${this.$route.params.id}`)
        .then(res => {
          this.load = false
          res.data.dados.map(item => {
            this.items.push(item)
          })
          this.totalRows = this.items.length
        })
    },
    resolveBadgeVariantStatus(status) {
      const colors = {
        'EM DIAS': 'light-success',
      }
      return colors[status] || 'light-secondary'
    },
    clearFormAgendamentos() {
      this.nome = ''
      this.endereco = ''
      this.cep = ''
      this.bairro = ''
      this.cidade = ''
      this.telefone = ''
      this.email = ''
      this.uf = ''
      this.numero = ''
      this.cpf_cnpj = ''
      this.observacao = ''
      this.prestadorSelected = []
      this.dateDefault = null
      this.periodoSelected = ''
    },
    clearFormReagendamento() {
      this.dateReagendamento = null
      this.observacaoReagendamento = ''
      this.agendamentoID = null
    },
    clearFormTransferencia() {
      this.agendamentoID = null
      this.prestadorSelected = []
      this.observacaoTransferencia = ''
    },
    openModalReagendamento(item) {
      this.agendamentoID = item.id
      if (item.previsao_reparo) {
        this.$refs.modalPrevisaoReparo.show()
      } else {
        this.$refs.modalReagendamento.show()
      }
    },
    newReagendamento() {
      this.load = true
      const body = {
        data: this.dateReagendamento,
        periodo: this.periodoSelected,
        evento: {
          motivo_evento_id: 4,
          descricao: this.observacaoReagendamento.toUpperCase(),
          ordem_de_servico_id: this.$route.params.id,
          cliente_id: this.items[0].ordem_servico.cliente_id,
        },
      }
      axios
        .post(`api/agendamento/reagendar/${this.agendamentoID}`, body)
        .then(() => {
          this.getAgendamentos()
          this.clearFormReagendamento()
          this.$swal({
            icon: 'success',
            title: 'Reagendado!',
            text: 'Reagendamento feito com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          this.getAgendamentos()
          this.clearFormReagendamento()
          if (error.response) {
            this.$swal({
              title: 'Erro ao realizar um reagendamento!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    transferirPrestador() {
      this.load = true
      const body = {
        agendamento_id: this.agendamentoID,
        prestador_de_servico_id: this.prestadorSelected.id,
        observacao: this.observacaoTransferencia,
      }
      axios
        .post('api/agendamento/transferencia/prestador', body)
        .then(() => {
          this.getAgendamentos()
          this.clearFormTransferencia()
          this.$swal({
            icon: 'success',
            title: 'Transferido!',
            text: 'Transferencia feita com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          this.getAgendamentos()
          this.clearFormTransferencia()
          if (error.response) {
            this.$swal({
              title: 'Erro!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    openModalPrevisaoReparo(id) {
      this.agendamentoID = id
      this.$refs.modalPrevisaoReparo.show()
    },
    newPrevisaoReparo() {
      this.load = true
      const body = {
        agendamento_id: this.agendamentoID,
        previsao_reparo: this.dataPrevisaoReparo,
      }
      this.observacaoPrevisaoReparo.length ? body.observacao = this.observacaoPrevisaoReparo : null
      axios
        .post('api/agendamento/previsao/reparo', body)
        .then(() => {
          this.agendamentoID = null
          this.dataPrevisaoReparo = ''
          this.observacaoPrevisaoReparo = ''
          this.getAgendamentos()
          this.$swal({
            icon: 'success',
            title: 'Nova Previsão!',
            text: 'Previsão de reparo feita com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          this.agendamentoID = null
          this.dataPrevisaoReparo = ''
          this.observacaoPrevisaoReparo = ''
          this.getAgendamentos()
          if (error.response) {
            this.$swal({
              title: 'Erro!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    copiarLink() {
      try {
        const hash = md5(this.$route.params.id).toString()
        const url = `http://vssyst.com/anexar/arquivos/${hash}`
        const textarea = document.createElement('textarea')
        textarea.value = url
        textarea.setAttribute('readonly', '')
        textarea.style.position = 'absolute'
        textarea.style.left = '-9999px'
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copiado',
            icon: 'CheckIcon',
            text: 'Copiado com sucesso',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'erro',
            icon: 'XIcon',
            text: `Erro ao copiar ${error}`,
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    async vincularTecnico(id) {
      this.agendamentoID = id
      await this.getPrestadores()
      this.$refs.modalVincularTecnico.show()
    },
    storeVinculoTecnico() {
      this.load = true
      const body = {
        agendamento_id: this.agendamentoID,
        prestador_servico_id: this.prestadorSelected.id,
      }
      axios
        .post('api/agendamento/vincular/prestador', body)
        .then(() => {
          this.getAgendamentos()
          this.clearFormTransferencia()
          this.$swal({
            icon: 'success',
            title: 'Vinculado!',
            text: 'Técnico vinculado com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          this.getAgendamentos()
          this.clearFormTransferencia()
          if (error.response) {
            this.$swal({
              title: 'Erro!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    getLinhas() {
      this.optionsLinhas = []
      axios.get(`api/empresas/linhas/${this.empresaID}`).then(res => {
        res.data.dados.map(item => {
          this.optionsLinhas.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
    getCidadesList() {
      this.optionsCidades = []
      axios.get('api/localidade/cidades').then(res => {
        res.data.dados.map(item => {
          this.optionsCidades.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },

    gerarLaudo(item) {
      this.$swal.fire({
        title: 'ATENÇÃO',
        text: 'Mudar status para LAUDO?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        cancelButtonText: 'Não!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const body = {
            ordem_de_servico_id: item.ordem_de_servico_id,
            status: 12,
            agendamento_id: item.id,
          }
          axios.put('api/ordem_servico/update-status-os', { ...body }).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Atualizado!',
              text: 'Ordem de serviço atualizada!',
              timer: 1500,
              showConfirmButton: false,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

            this.reload()
          }).catch(() => {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: 'Ocorreu um erro',
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.nova-entidade {
  float: right;
  margin-right: 5px;
}

.nova-entidade:hover {
  color: blueviolet;
  cursor: pointer;
}
</style>
