<template>
  <b-overlay
    :show="load"
    variant="transparent"
  >
    <b-card>
      <b-row>
        <b-col
          md="2"
          xl="2"
        >
          <!-- readonly input -->
          <b-form-group
            label="ID"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              :value="info.id"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          xl="2"
        >
          <!-- readonly input -->
          <b-form-group
            label="Sinistro"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              :value="`#${info.sinistro}`"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          md="3"
          xl="3"
        >
          <!-- readonly input -->
          <b-form-group
            label="Data Abertura"
            label-for="readOnlyInput"
          >
            <b-form-input
              id="readOnlyInput"
              :value="info.data_abertura ?formatTimezone(info.data_abertura) : ''"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          md="5"
          xl="5"
        >
          <!-- readonly input -->
          <b-form-group
            label="Tipo de Atendimento"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.tipo_atendimento"
              readonly
            />
            <b-form-select
              v-if="editar"
              v-model="info.tipo_atendimento"
              :options="optionsAtendimento"
            />
          </b-form-group>
        </b-col>
        <b-col class="col-12">
          <hr>
        </b-col>
        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Cliente"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.cliente.nome"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.cliente.nome"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          xl="2"
        >
          <!-- readonly input -->
          <b-form-group
            label="CPF/CNPJ"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.cliente.cpf_cnpj"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.cliente.cpf_cnpj"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="3"
          xl="3"
        >
          <!-- readonly input -->
          <b-form-group
            label="E-mail"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.cliente.email"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.cliente.email"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="3"
          xl="3"
        >
          <!-- readonly input -->
          <b-form-group
            label="Telefone"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.cliente.telefone"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.cliente.telefone"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Endereco"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.cliente.endereco"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.cliente.endereco"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          xl="2"
        >
          <b-form-group
            label="Número"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.cliente.numero"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.cliente.numero"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          xl="2"
        >
          <!-- readonly input -->
          <b-form-group
            label="CEP"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.cliente.cep"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.cliente.cep"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          xl="2"
        >
          <!-- readonly input -->
          <b-form-group
            label="Bairro"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.cliente.bairro"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.cliente.bairro"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          xl="2"
        >
          <!-- readonly input -->
          <b-form-group
            label="Cidade"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              :value="info.cliente.cidade ? info.cliente.cidade.nome : ''"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="nomeCidade"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          xl="1"
        >
          <!-- readonly input -->
          <b-form-group
            label="UF"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.cliente.uf"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.cliente.uf"
            />
          </b-form-group>
        </b-col>

        <b-col class="col-12">
          <hr>
        </b-col>
        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Produto"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              :value="info.produto ? info.produto.nome : ''"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.produto.nome"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Marca"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.produto.marca.nome"
              readonly
            />
            <v-select
              v-if="editar"
              v-model="info.produto.marca_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsMarcas"
              :clearable="false"
              class="mb-2 item-selector-title"
            />
          </b-form-group>

        </b-col>
        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Revenda"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.produto.revenda.nome"
              readonly
            />
            <v-select
              v-if="editar"
              v-model="info.produto.revenda_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsRevendas"
              :clearable="false"
              class="mb-2 item-selector-title"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Linha"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.produto.linha.nome"
              readonly
            />
            <v-select
              v-if="editar"
              v-model="info.produto.linha_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsLinhas"
              :clearable="false"
              class="mb-2 item-selector-title"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Tipo de garantia"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.produto.tipo_garantia"
              readonly
            />
            <v-select
              v-if="editar"
              v-model="info.produto.tipo_garantia"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsTipoGarantia"
              :clearable="false"
              class="mb-2 item-selector-title"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Seguradora"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.produto.seguradora.nome"
              readonly
            />
            <v-select
              v-if="editar"
              v-model="info.produto.seguradora_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsSeguradora"
              :clearable="false"
              class="mb-2 item-selector-title"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Valor do produto"
            label-for="readOnlyInput"
          >
            <money
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.produto.valor_bruto"
              v-bind="money"
              class="form-control"
              readonly
            />
            <money
              v-if="editar"
              id="readOnlyInput"
              v-model="info.produto.valor_bruto"
              v-bind="money"
              class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Modelo"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.produto.modelo"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.produto.modelo"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Nº Série"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.produto.numero_serie"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.produto.numero_serie"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Certificado"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.produto.certificado"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.produto.certificado"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Nº NF"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.produto.numero_nf"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.produto.numero_nf"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="4"
        >
          <!-- readonly input -->
          <b-form-group
            label="Imei"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.produto.imei"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.produto.imei"
            />
          </b-form-group>
        </b-col>
        <b-col class="col-12">
          <hr>
        </b-col>
        <b-col
          md="12"
          xl="12"
        >
          <!-- readonly input -->
          <b-form-group
            label="Relato do Cliente"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.relato_cliente"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.relato_cliente"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          xl="12"
        >
          <!-- readonly input -->
          <b-form-group
            label="Observação"
            label-for="readOnlyInput"
          >
            <b-form-input
              v-if="!editar"
              id="readOnlyInput"
              v-model="info.observacao"
              readonly
            />
            <b-form-input
              v-if="editar"
              id="readOnlyInput"
              v-model="info.observacao"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <div
            v-show="editar"
            class="btn-group"
          >
            <b-button
              variant="primary"
              class="ml-auto"
              style="margin-right: 10px;"
              @click="editarInfoOS"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span>Salvar</span>
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="telaInfoOS()"
            >
              <feather-icon
                icon="XIcon"
                class="mr-50"
              />
              <span>Cancelar</span>
            </b-button>
          </div>
          <b-button
            v-show="!editar"
            variant="primary"
            @click="btEditarOS"
          >
            <span>Editar</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>

</template>

<script>
import { BFormInput, BRow, BCol, BFormGroup, BCard, BButton, BOverlay, BFormSelect } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { Money } from 'v-money'
import axios from '../../../../../axios-auth'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BButton,
    BOverlay,
    BFormSelect,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    Money,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
    reload: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      load: false,
      editar: false,
      optionsTipoGarantia: [
        'SEGURADORA',
        'GARANTIA DE FÁBRICA',
        'FORA DE GARANTIA',
      ],
      optionsAtendimento: [
        'NORMAL',
        'PRIORITÁRIO',
        'EMERGENCIAL',
        'DANOS ELÉTRICOS',
        'ASSISTÊNCIA FUNERAL',
        'REINCIDÊNCIA',
      ],
      optionsMarcas: [],
      optionsRevendas: [],
      optionsLinhas: [],
      optionsSeguradora: [],
      invoice: {
        tipo_atendimento: null,
        relato_cliente: null,
        observacao: null,
      },
      customer: {
        nome: null,
        telefone: null,
        email: null,
        endereco: null,
        cep: null,
        bairro: null,
        cidade: null,
        uf: null,
        numero: null,
        cpf_cnpj: '',
      },
      produto: {
        marca_id: null,
        linha_id: null,
        revenda_id: null,
        seguradora_id: null,
        tipo_garantia: null,
        nome: null,
        valor_custo: null,
        valor_bruto: null,
        numero_serie: null,
        modelo: null,
        certificado: null,
        numero_nf: null,
        imei: null,
        garantia_fabrica: false,
      },
    }
  },
  computed: {
    nomeCidade: {
      get() {
        return this.info.cliente.cidade ? this.info.cliente.cidade.nome : ''
      },
      set(newValue) {
        if (this.info.cliente.cidade) {
          this.info.cliente.cidade.nome = newValue
        } else {
          this.info.cliente.cidade = { nome: newValue }
        }
      },
    },
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    this.empresaNome = JSON.parse(localStorage.getItem('userData'))?.empresa
    this.getSeguradoras()
    this.getLinhas()
    this.getMarcas()
    this.getRevendas()
  },
  methods: {
    btEditarOS() {
      this.editar = true
    },
    telaInfoOS() {
      this.editar = false
    },
    editarInfoOS() {
      this.load = true
      const cidadeNome = this.info.cliente.cidade ? this.info.cliente.cidade.nome : null
      const cliente = {
        cliente_id: this.info.cliente_id,
        nome: this.info.cliente.nome,
        telefone: this.info.cliente.telefone,
        email: this.info.cliente.email,
        endereco: this.info.cliente.endereco,
        cep: this.info.cliente.cep,
        numero: this.info.cliente.numero,
        bairro: this.info.cliente.bairro,
        cidade: cidadeNome,
        uf: this.info.cliente.uf,
        cpf_cnpj: this.info.cliente.cpf_cnpj,
      }

      const produto = {
        produto_id: this.info.produto_id,
        marca_id: this.info.produto.marca_id.id,
        modelo: this.info.produto.modelo,
        revenda_id: this.info.produto.revenda_id.id,
        seguradora_id: this.info.produto.seguradora_id.id,
        linha_id: this.info.produto.linha_id.id,
        tipo_garantia: this.info.produto.tipo_garantia,
        nome: this.info.produto.nome,
        valor_custo: this.info.produto.valor_bruto,
        valor_bruto: this.info.produto.valor_bruto,
        numero_serie: this.info.produto.numero_serie,
        certificado: this.info.produto.certificado,
        numero_nf: this.info.produto.numero_nf,
        imei: this.info.produto.imei,
        garantia_fabrica: this.info.produto.garantia_fabrica,
      }

      const os = {
        ordem_de_servico_id: this.info.id,
        tipo_atendimento: this.info.tipo_atendimento,
        relato_cliente: this.info.relato_cliente,
        observacao: this.info.observacao,
      }

      const formData = {
        os,
        cliente,
        produto,
      }

      axios.put('api/ordem_servico/update', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.load = false
          this.editar = false
          this.reload()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${res.data.mensagem}`,
              icon: 'UserIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$swal({
            title: 'Erro ao salvar!',
            text: error.response.data.mensagem,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    real(value) {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(value.replace('R$', '').replace(',', '.').trim())) {
        let valor = value.replace('R$', '').replace(',', '.').trim()
        valor = parseFloat(valor)
        const formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
        this.produto.valor_bruto = formatter.format(valor)
      } else {
        this.produto.valor_bruto = 0
      }
    },
    removerString(value) {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(value.replace('R$', '').replace(',', '.').trim())) {
        this.produto.valor_bruto = 0
      } else {
        this.produto.valor_bruto = value.replace('R$', '').replace(',', '.').trim()
      }
    },
    getSeguradoras() {
      this.optionsSeguradora = []
      axios.get(`api/empresas/seguradoras/${this.empresaID}`).then(res => {
        res.data.dados.map(item => {
          this.optionsSeguradora.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
    getRevendas() {
      this.optionsRevendas = []
      axios.get(`api/empresas/revendas/${this.empresaID}`).then(res => {
        res.data.dados.map(item => {
          this.optionsRevendas.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
    getMarcas() {
      this.optionsMarcas = []
      axios.get(`api/empresas/marcas/${this.empresaID}`).then(res => {
        res.data.dados.map(item => {
          this.optionsMarcas.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
    getLinhas() {
      this.optionsLinhas = []
      axios.get(`api/empresas/linhas/${this.empresaID}`).then(res => {
        res.data.dados.map(item => {
          this.optionsLinhas.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
  },
}
</script>
