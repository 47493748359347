<template>
  <div>
    <!-- modal anexar arquivos-->
    <b-modal
      id="modal-historico"
      ref="modalHistorico"
      cancel-variant="outline-secondary"
      ok-title="Salvar"
      no-close-on-backdrop
      cancel-title="Cancelar"
      title="Novo Histórico"
      @ok="newHistorico"
    >
      <b-col
        md="12"
        class="mt-1"
      >
        <label>Motivo: </label>
        <v-select
          v-model="eventos.selected"
          :options="eventos.options"
        />
        <div v-if="eventos.selected && eventos.selected.id === 20">
          <label>Selecione a tabulação:</label>
          <v-select
            v-model="selectedTabulacao"
            :options="optionsTabulacao"
          />
        </div>
        <label class="d-inline">Descrição: </label>
        <b-input-group>
          <b-form-textarea v-model="descricao" />
        </b-input-group>
      </b-col>
      <b-col
        md="12"
        class="mt-1"
      >
        <b-form-group>
          <b-form-checkbox
            id="checkbox-2"
            v-model="informacaoPrivada"
            name="checkbox-2"
          >
            Informação Privada
          </b-form-checkbox>
        </b-form-group>

      </b-col>
    </b-modal>
    <!-- modal Hisorico pdf-->
    <b-modal
      id="modal-view-historico"
      ref="modal-view-historico"
      hide-footer
      size="xl"
      title="PDF Histórico"
      @hidden="pdfPreview = ''"
    >
      <b-row id="frameRow">
        <b-col>
          <iframe
            id="myFrame"
            :src="pdfPreviewHistorico"
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-row>
      <b-col
        md="12"
        class="my-1"
      >
        <!-- primary -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="float-right"
          variant="outline-primary"
          size="sm"
          @click="openModalHistorico"
        >
          <feather-icon
            icon="PlusCircleIcon"
            class="mr-50"
          />
          <span class="align-middle">Novo Histórico</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="float-right mr-1"
          variant="outline-warning"
          size="sm"
          @click="exportFile"
        >
          <feather-icon
            icon="FileTextIcon"
            class="mr-50"
          />
          <span class="align-middle">Exportar Excel</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="float-right mr-1"
          variant="outline-warning"
          size="sm"
          @click="openModalPdfHistorico"
        >
          <feather-icon
            icon="FileIcon"
            class="mr-50"
          />
          <span class="align-middle">Exportar PDF</span>
        </b-button>
      </b-col>
    </b-row>

    <b-overlay
      :show="load"
      variant="transparent"
    >
      <app-timeline v-if="dataHistorico">
        <app-timeline-item
          v-for="item in historico.eventos"
          :key="item.id"
          :variant="varianteEvento(item.usuario_empresa.funcao.nome)"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ item.motivo_evento.nome }}</h6>
            <small class="text-muted">{{ item.created_at ? dataHora(item.created_at) : '' }}</small>
          </div>
          <div class="demo-spacing-0">
            <b-alert
              :variant="varianteEvento(item.usuario_empresa.funcao.nome)"
              show
            >
              <div class="alert-body">
                <span>{{ item.descricao }}</span>
              </div>
            </b-alert>
          </div>
          <b-media>
            <template #aside>
              <b-avatar
                :text="getInitials(item.usuario_empresa.user.name)"
                size="32"
              />
            </template>
            <h6>{{ item.usuario_empresa.user.name }}</h6>
            <p>{{ item.usuario_empresa.funcao.nome }}</p>
          </b-media>
          <hr>
        </app-timeline-item>
      </app-timeline>
    </b-overlay>
  </div>

</template>

<script>
import {
  BAvatar, BMedia, BButton, VBToggle, VBTooltip, BCol, BRow, BModal, BFormTextarea, BFormCheckbox, BAlert, BInputGroup, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import { saveAs } from 'file-saver'
import axios from '../../../../../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BButton,
    BCol,
    BRow,
    BModal,
    BFormTextarea,
    BFormCheckbox,
    BAlert,
    BInputGroup,
    BFormGroup,
    vSelect,
    BOverlay,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
    reload: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      pdfPreviewHistorico: '',
      historico: [],
      dataHistorico: false,
      eventos: {
        options: [],
        selected: [],
      },
      descricao: '',
      informacaoPrivada: false,
      load: true,
      optionsTabulacao: [
        'VISITA NÃO CUMPRIDA',
        'DEMORA NO REPARO',
        'MAU ATENDIMENTO',
        'OUTROS',
      ],
      selectedTabulacao: '',
    }
  },
  created() {
    this.getHistoricoOs()
  },
  methods: {
    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },
    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório Histórico')
      const newHistorico = []

      this.historico.eventos.map(i => {
        newHistorico.push({
          id: i.id,
          data: i.created_at,
          motivo: i.motivo_evento.nome,
          descricao: i.descricao,
          // usuario: i.usuario_empresa.user.name,
        })
      })
      const itemsPlan = newHistorico

      worksheet.columns = [
        { header: 'id', key: 'id' },
        { header: 'data', key: 'data' },
        { header: 'motivo', key: 'motivo' },
        { header: 'descricao', key: 'descricao' },
        //  { header: 'usuario', key: 'usuario' },
      ]

      itemsPlan.map(item => {
        worksheet.addRow([
          item.id,
          item.data ? this.dataHora(item.data) : null,
          item.motivo,
          item.descricao,
          // item.usuario,
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório Historico', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    dataHora(dados, op = false) {
      const a = dados
      const b = a.split('T')
      const c = b[0].split('-')
      const d = b[1].split('.')
      const e = d[0].split(':')
      const dataBr = `${c[2]}/${c[1]}/${c[0]}`
      const horaBr = `${e[0]}:${c[1]}`
      const dataHora = `${dataBr} ${horaBr}`
      if (op) {
        return dataBr
      }
      return dataHora
    },
    getHistoricoOs() {
      this.historico = []
      axios
        .get(`api/ordem_servico/historico/${this.$route.params.id}`)
        .then(res => {
          this.historico = res.data.dados
          this.historico.eventos.reverse()
          this.load = false
          this.dataHistorico = true
        })
    },
    async openModalHistorico() {
      await this.getMotivosEventos()
      this.$refs.modalHistorico.show()
    },
    openModalPdfHistorico() {
      axios.get(`api/ordem_servico/gerar/pdf/historico/${this.$route.params.id}`, {
        headers: {
          Accept: 'application/pdf',
        },
        responseType: 'arraybuffer',
      })
        .then(res => {
          const file = new File([res.data], 'historico.pdf', {
            type: 'application/pdf',
          })
          const url = URL.createObjectURL(file)
          this.pdfPreviewHistorico = url
          this.$refs['modal-view-historico'].show()
        })
    },
    getMotivosEventos() {
      this.eventos.options = []
      axios.get('api/motivo_evento/').then(res => {
        res.data.dados.map(item => {
          if (item.status === 1) {
            this.eventos.options.push({
              label: item.nome,
              id: item.id,
            })
          }
        })
      })
    },
    newHistorico() {
      this.load = true
      const body = {
        ordem_de_servico_id: parseInt(this.$route.params.id),
        motivo_evento_id: this.eventos.selected.id,
        descricao: this.descricao.toUpperCase(),
      }
      this.informacaoPrivada ? body.privada = this.informacaoPrivada : null
      this.selectedTabulacao.length ? body.tabulacao = this.selectedTabulacao : null
      axios
        .post('api/evento/store', body)
        .then(() => {
          this.reload()
          this.getHistoricoOs()
          this.clearFormModalHistorico()
          this.$swal({
            title: 'SUCESSO!',
            icon: 'success',
            text: 'HISTÓRICO CADASTRADO COM SUCESSO!',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.reload()
          this.getHistoricoOs()
          this.clearFormModalHistorico()
          this.$swal({
            title: 'ERRO!',
            icon: 'error',
            text: `Ocorreu um erro durante o cadastro: ${error.response.data.mensagem} `,
            customClass: {
              confimButton: 'btn btn-danger',
            },
          })
        })
    },
    clearFormModalHistorico() {
      this.descricao = ''
      this.informacaoPrivada = false
      this.eventos.selected = []
      this.selectedTabulacao = ''
    },
    varianteEvento(setor) {
      const colors = {
        AUDITOR: 'warning',
        ATENDENTE: 'primary',
        DIRECIONADOR: 'info',
        EXTERNO: 'danger',
        FINANCEIRO: 'success',
        ORCAMENTO: 'secondary',
      }
      return colors[setor] || 'secondary'
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
